import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.div`
    max-width: 70%;
    margin: auto;
    padding: 50px;

    @media(max-width: 800px) {
        width: 95%;
        padding: 0;
    }
    
    h1{
        color: #fff;
    }
    hr {
        border: 0;
        height: 1px;
        background: #fff;
        margin: 10px 0 20px;
    }

`

export const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    flex-direction: row;

    @media(max-width: 800px) {
        width: 100%;

        display: flex;
        flex-direction: column;
    }
`

export const FormInfo = styled.div`
    width: 100%;
    align-items: center;
    padding: 0 0 0 2px;
    display: flex;
    flex: 1;
    
    form {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;

        label {
            margin: 0;
            color: #fff;
        }

        input {
            background: rgba(0, 0, 0, 0.1);
            border: 0;
            border-radius: 4px;
            height: 44px;
            padding: 0 15px;
            color: #fff;
            margin: 0 0 10px;
            width: 100%;
    
            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }
        }
    
        button {
            margin: 5px 0 0;
            height: 44px;
            background: #3b9eff;
            font-weight: bold;
            color: #fff;
            border: 0;
            border-radius: 4px;
            font-size: 16px;
            transition: background 0.3s;
    
            &:hover {
                background: ${darken(0.05, '#0885ff')}
            }
        }
    }

`

const imgWidth = 200;
const imgHeight = imgWidth;

export const FormImage = styled.div`
    width: 50%;;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 20px 0px;

    @media(max-width: 800px) {
        width: 100%;
    }

    form {
        position: relative;
        padding: 0;
        margin-bottom: 0;
        max-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media(max-width: 800px) {
            width: 100%;
        }

        input {
            background: rgba(0, 0, 0, 0.1);
            border: 0;
            border-radius: 4px;
            height: 44px;
            padding: 0 15px;
            color: #fff;
            margin-top: 25px;

            @media(max-width: 800px) {
                width: 100%;
            }
    
            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }
        }

        button {
            width: 100%;
            margin-top: 10px;
            padding: 0 10px;
    
            height: 44px;
            background: #3b9eff;
            font-weight: bold;
            color: #fff;
            border: 0;
            border-radius: 4px;
            font-size: 16px;
            transition: background 0.3s;
    
            &:hover {
                background: ${darken(0.05, '#0885ff')}
            }
        }
    }

    div {
        width: ${imgWidth}px;
        height: ${imgHeight}px;
        background-size: cover !important;
        border-radius: 50%;
    }

    label {
        position: absolute;
        top: calc(${imgHeight}px - ${imgHeight/4}px);
        left: calc(${imgWidth}px - ${imgHeight/2}px);
        padding: 1rem;
        border: none;
        border-radius: 50%;
        color: white;
        font-size: 1.8rem;
        font-weight: normal;
        cursor: pointer;
        background: #fff;
        opacity: 0.7;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.5s;
    }

    label:hover {
        opacity: 1;
    }

    input[type="file"] {
        display: none;
    }

    span{
        color: #fff;
        background: #f0ad4e;
        padding: 10px;
        margin: 10px 0;
        text-align: center;

        border-radius: 5px;

        @media(max-width: 800px) {
            width: 100%;
            max-width: 300px;
        }
    }

`