import React from 'react';

import {Container} from './styles'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import logo from '../../assets/logos/logo.svg'

import "leaflet/dist/leaflet.css";
import Leaflet from 'leaflet'
const Mapa = (props) => {

    const mapIcon = Leaflet.icon({
        iconUrl: logo,
        iconSize: [40, 50],
        iconAnchor: [35, 50],
        popupAnchor: [0, -40]
    })

  return (
      <Container>
        <MapContainer center={[-6.4826169, -35.4323927]} zoom={17} scrollWheelZoom={true}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker icon={mapIcon} position={[-6.4826169, -35.4323927]}>
                <Popup closeButton={false}>
                    <h4>Oral center</h4>
                    <p>R. Assis Chateubriand, 1067 - São Sebastião, Nova Cruz/RN</p>
                </Popup>
            </Marker>
        </MapContainer>
      </Container>
  );
};

export default Mapa;