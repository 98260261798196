import React, {useState, useContext} from 'react';

import { Link, useRouteMatch } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'
import { Container, Return, Paciente} from './styles'
import { Form, Input, Textarea, Select  } from '@rocketseat/unform'
import {toast} from 'react-toastify'
import { useHistory } from 'react-router-dom'

import Loading from '../../../components/loading/index'
import Logo from '../../../assets/logos/logo-branca.svg'

import Autocomplete from '../../../components/autocomplete'

import api from '../../../services/api'
import {AutocompleteContext} from '../../../context/autocomplete/AutocompleteContext'

export default function RegisterConsulta(){

    const { params } = useRouteMatch();

    const [loading, setLoading] = useState(false);

    const { paciente } = useContext(AutocompleteContext)

    var history = useHistory()

    async function handlSubmit(data) {
        setLoading(true)  
        try {
            if(!paciente){
                toast.info('Uma consulta deve possuir um paciente.')
                setLoading(false) 
            } else {
                await api.post(`consultas/register` ,{
                    user: paciente[0]._id,
                    data: params.data,
                    situation: data.situation,
                    note: data.note,
                    project: data.project
                 }).then(async () => {
                     toast.success('Consulta cadastrado')
                     history.push('/dashboard')
                }).catch((error) => {
                    setLoading(false) 
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
            }
        } catch (error) {
            toast.error('Ocorreu um erro ao registrar a consulta. Entre em contato com o suporte.')
        }     
    }

    if (loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return (
            <>
                <Return>
                    <Link to="/dashboard">
                        <FiChevronLeft/>
                        Voltar
                    </Link>
                </Return>
                <Container>
                    <header>
                        <img src={Logo} alt="Carol Nutri"/>
                        <h1>Informe os dados cadastrar a consulta.</h1>
                    </header>

                    <Autocomplete urlFull={`/user/usersFull`} urlBusca="user/userEmail/" parametroDeBusca="email" textPlaceholder="Informe o e-mail do paciente"/>
                    {
                    paciente ?
                        paciente.map(paciente => (
                            <>
                                <Paciente>
                                    <Link key={String(paciente._id)} >
                                        <img alt="Carol Bernadino Nutricionista"  src={paciente.avatar}/>
                                        <div>
                                            <div className="conteudo">
                                                <strong>{paciente.name}</strong>
                                                <p>{paciente.email}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </Paciente>
                            </>
                        ))
                    :
                    undefined
                    }

                    <Form className="formulario" onSubmit={handlSubmit}>
                        <Input label="Acompanhamento" name="project" id="project" placeholder="Link do documento do drive"  />
                        <Select label="Situação" name="situation" id="situation" options={[{id: "Em espera", title: 'Em espera'}, {id: "Em acompanhamento", title: 'Em acompanhamento'}, {id: "Em acompanhamento pós retorno", title: 'Em acompanhamento pós retorno'}, {id: "Finalizado", title: 'Finalizado'}]} placeholder="Selecione uma opção" />
                        <Textarea rows="4" label="Observações" name="note" placeholder="Observações sobre a consulta" />
                        <button type="submit" className="salvan">Cadastrar</button>
                    </Form>
                </Container>
            </>
        )
    }
}