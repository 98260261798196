import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;


    widith:100%;
    padding: 25px;

    @media(max-width: 800px) {
        padding: 10px;
    }

    p{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        widith:100%;
        margin:0;

        @media(max-width: 800px) {
            font-size: 14px;
            widith:100%;

        }

    }
`