import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

import {Container} from './styles.js'

const Toggle  = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Button color="transparent" onClick={toggle} style={{ marginBottom: '1rem' }}>{props.children[0]}</Button>
      <Collapse className="janela" isOpen={isOpen}>
        <Card>
            <CardBody>
                    {props.children[1]}
            </CardBody>
        </Card>
      </Collapse>
    </Container>
  );
}

export default Toggle;