import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import { Content1, Container} from './styles.js'

import { FcCalendar, FcInfo, FcTodoList } from "react-icons/fc";

const Tabs = (props) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const {
    title1,
    title2,
    title3
  } = props;

  return (
    <Container>
      <Nav tabs>
        <Content1>
          {
            title1 ?
              <NavItem className="tabs">
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  <div>
                    <h5>{title1}</h5>{" "}<FcTodoList/>
                  </div>
                </NavLink>
              </NavItem>

            :
              undefined
          }

          {
            title2 ?

            <NavItem className="tabs">
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                <div>
                  <h5>{title2}</h5>{" "}<FcCalendar/>
                </div>
              </NavLink>
            </NavItem> 
            :
            undefined

          }

          {
            title3 ?
            <NavItem className="tabs">
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}
              >
                <div>
                  <h5>{title3}</h5>{" "}<FcInfo/>
                </div>
              </NavLink>
            </NavItem>
          :
          undefined
          }
        </Content1>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {props.children[0]}
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <Col sm="12">
            {props.children[1]}
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Row>
            <Col sm="12">
            {props.children[2]}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Container>
  );
}

export default Tabs;