import React from 'react';

import {Container} from './styles'

const Loading = (props) => {


  return (
    <Container>
        <div className="loading"></div>
        {props.children}
    </Container>
  );
};

export default Loading;