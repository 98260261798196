import styled, { keyframes} from 'styled-components';

const DaDireitaParaEsquerda = keyframes`
    from{
        opacity: 0;
        transform: translateX(10%)

    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const Container = styled.div`
    padding: 30px 0;
    max-width: 90%;
    margin: auto;

    
    @media(max-width: 800px) {
        max-width: 95%;
        padding: 15px 0;
    }
    
    img {
        width:50%;
        height: 25%;
        margin:0;

        @media(max-width: 800px) {
            margin: auto;
            width:85%;
            height:100%;
        }
    }
    

`
export const Time = styled.li`
    animation: ${DaDireitaParaEsquerda} 0.2s;
    min-width: 100%;
    padding: 20px;
    border-radius: 8px;
    background: #fff;


    @media(max-width: 800px) {
        padding: 10px;
    }

    a {
        display: block;
        text-decoration: none;
        color: #831e62;
    }

    opacity: ${props => (props.past ? 0.6 : 1)};

    strong {
        display: block;
        text-decoration: none;
        color: ${props => (props.available ? '#831e62' : '#831e62')};
        @media(max-width: 800px) {
            font-size: 14px;
        }
    }

    span {
        display: block;
        text-decoration: none;
        margin-top: 3px;
        font-weight: bold;
        font-size: 18px;
        @media(max-width: 800px) {
            font-size: 12px;
        }
        color: ${props => (props.available ? '#831e62' : '#831e62')};
    }
    p{
        font-size: 16px;
        @media(max-width: 800px) {
            font-size: 12px;
    }
`



export const MedicalInfo2 = styled.div`
    widith:100%;
    margin: 0;

    form {
        display: flex;
        flex-direction: column;
        

        div {
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width: 800px) {
                display: flex;
                flex-direction: column;
            }

            select {
                width: 100%;
                background: #be317f;
                border: 0;
                border-radius: 4px;
                height: 40px;
                padding: 0 15px;
                color #fff;
                margin: 0 10px;

                option{
                    color: #fff;
                    background: #9D266F;
                }
                
                @media(max-width: 800px) {
                    font-size: 12px;
                    margin: 10px;
                    height: 36px;
                }
        
                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }

            }

            textarea {
                background: #be317f;
                color #fff;

                border: 0;
                border-radius: 4px;
                padding: 10px;

                width: 100%;

                @media(max-width: 800px) {
                    font-size: 12px;
                }
        
                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            
        }
    }

        .Salve{
            width: 100%;
            background: #3b9eff;
            color #fff;
            margin: 10px;;
            height: 36px;
            font-weight: bold;
            border: 0;
            border-radius: 4px;
            font-size: 16px;
            transition: background 0.5s;

            &:hover{
                background: #3273ed;
            }
        }
    
`

export const Radio = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    padding: 0;

    hr{
        display: none;
        @media(max-width: 800px) {
            width:100%;
            color: #fff;
            display: block;
        }
    }

    h3{
        color: #fff;
        margin: 0;
        padding:0;
        @media(max-width: 800px) {
            margin-top: 5px;
        }
    }

    img {
        width: 100%;
        height: 85px;
        @media(max-width: 800px) {
            margin: auto;
            width: 90%;
            height: 70px;
        }
        -webkit-box-shadow: 0px 15px 30px 0px rgba(16,146,147,0.12);
        -moz-box-shadow: 0px 15px 30px 0px rgba(16,146,147,0.12);
        box-shadow: 0px 15px 30px 0px rgba(16,146,147,0.12);
        border-radius: 5px;
        -webkit-transition-duration: .2s;
        -moz-transition-duration: .2s;
        -o-transition-duration: .2s;
        transition-duration: .2s;
        cursor: pointer;

        &:hover {
            transform: scale(1.01);
        }
    }
    .radio-hidden{
        position: relative;
        visibility: hidden;
        border-width: medium;
        height: 2px;
    }
    input[type=radio]:checked + label {
        .radio-image{
            box-shadow: 0 0 5px 5px rgba(170,170,255,0.7);
            border-width: medium;
            border-style: ridge;
            border-color: #fff;
        }
    }
`

export const MedicalInfo = styled.div`
    widith:100%;
    margin:0;

    form {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            width: 100%;

            @media(max-width: 800px) {
                display: flex;
                flex-direction: column;
            }

            label{
                display: flex;
                flex-direction: column;

                justify-content: center;
                margin: 0;
                color : #fff;
                width: 100%;

                text-shadow: 1px 1px 3px black;
                
                @media(max-width: 800px) {
                    font-size: 12px;
                }
            }

            input, select {
                width: 100%;
                background: rgba(0, 0, 0, 0.1);
                border: 0;
                border-radius: 4px;
                height: 40px;
                padding: 0 15px;
                color #fff;
                margin:10px;

                option{
                    color: #9D266F;
                    background: #fff;
                }
                
                @media(max-width: 800px) {
                    font-size: 12px;
                    margin: 10px;
                    height: 36px;
                }
        
                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            textarea {
                background: rgba(0, 0, 0, 0.1);
                border: 0;
                border-radius: 4px;
                padding: 10px;
                color #fff;
                width: 100%;
                margin:15px;

                @media(max-width: 800px) {
                    font-size: 12px;
                }

        
                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            
        }
    
        hr {
            border: 0;
            height: 1px;
            background: #fff;
            margin: 10px 0 20px;
        }
    }

        .Salve{
            width: 100%;
            background: #3b9eff;
            color #fff;
            margin: 10px auto;;
            height: 36px;
            font-weight: bold;
            border: 0;
            border-radius: 4px;
            font-size: 16px;
            transition: background 0.5s;

            &:hover{
                background: #3273ed;
            }
        }
    
`
export const Content = styled.div`
    padding: 30px 0;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(max-width: 800px) {
        width: 95%;
    }

    h2 {
        animation: ${DaDireitaParaEsquerda} 0.2s;
        width:100%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        color: #831e62;
        padding: 5%;
        margin: 4%;
        transition: 1.2s;

        &:hover{
            box-shadow: 2px 4px black;
        }

        @media(max-width: 800px) {
            font-size: 15px;
        }

    }
    svg {
        color: #ff0000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;

        @media(max-width: 800px) {
            margin: 3px;
        }
    }
    
    div {
        display: flex;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0;
    
        img {
            height: 75px;
            margin: 0;
            align-item: center;
        
            @media(max-width: 800px) {
                height: 50px;
            }
        }
    
        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin: 0;

            @media(max-width: 800px) {
                font-size: 20px;
                margin: 0 7px;
            }
        }
    }
    
    header {
        display: flex;
        align-self: center;
        align-items: center;

        button {
            border: 0;
            background: none;
        }

        strong {
            color: #fff;
            font-size: 24px;
            margin: 0 15px;

            @media(max-width: 800px) {
                font-size: 16px;
                margin: 0 7px;
            }
            
        }
    }

    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin-top: 30px;
    }
`