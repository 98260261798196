import styled, {keyframes} from 'styled-components'

const title = keyframes`

    50% {
        color: #C0C0C0;
    }
    10% {
        color: transparent;
    }

`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const Formulario = styled.form`
    width: 100%;
    margin-top: 20px;
    display:  flex;

    input {
        color: #3a3a3a;
        width: 500px;
        height: 60px;
        padding: 0 24px;
        border: 0;
        border-radius: 5px 0 0 5px;

        @media(max-width: 800px) {
            height: 40px;
            width: 250px;
        }
    }

    button {
        background: #DCDCDC;
        width: 75px;
        height: 60px;
        border-radius: 0 5px 5px 0;
        border: 0;
        transition: background 0.3s;

        @media(max-width: 800px) {
            height: 40px;
        }

        &:hover {
            background: #C0C0C0;
        }
    }

    div{
        display: flex;
       
        svg{
            margin-left: 30px;
            color: #fff;
            transition: .6s;
            cursor:pointer;
            &:hover {
            transform: rotate(90deg);
            }
        }
    }

`
export const Carregando = styled.div`
    padding: 10px;
    color: #DCDCDC;
    animation: ${title} 1.8s linear infinite;

`

