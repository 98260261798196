import styled from 'styled-components';

export const Container = styled.div`
    width:70%;
    padding: 25px 0;
    margin: auto;

    .calendario{
        background: #fff;
        border: none;
        border-radius: 6px;
        width: 100%;
        height:100%;
        padding:0;
        margin-top:15px;

        color: #831e62;
    }
    
    img {
        width:50%;
        height: 25%;
        margin:0;
    }

    .navegador {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: 0;
            background: none;
            margin: 0 25px;
        }

        strong {
            color: #fff;
            font-size: 24px;
            margin: 0;

            @media(max-width: 800px) {
                font-size: 16px;
                margin: 0 7px;
            }
            
        }
    }



        .consultas{
            width:100%;
            height: 100%;

            margin: auto;
        }

        .agendamento{
            width:100%;
            height: 100%;

        }

        ul {
            height: 100%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 15px;
            width:100%;
            margin: 30px 0;
        }

    }
`

export const Time = styled.li`
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background: #fff;

    @media(max-width: 800px) {
        padding: 10px;
    }

    a {
        display: block;
        text-decoration: none;
        color: #831e62;

        strong {
            color: #831e62;
            display: block;
            text-decoration: none;
            margin:0;
            
            @media(max-width: 800px) {
                font-size: 10px;
            }
        }

        span {
            display: block;
            text-decoration: none;
            margin-top: 3px;
            font-weight: bold;
            font-size: 18px;
            @media(max-width: 800px) {
                font-size: 10px;
            }
            color: ${props => (props.available ? '#831e62' : '#831e62')};
        }
    }

    opacity: ${props => (props.past ? 0.6 : 1)};

    p{
        font-size: 16px;
        margin: 0;
        padding: 0;
        @media(max-width: 800px) {
        font-size: 10px;
    }
`