import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom'

import {toast} from 'react-toastify'
import { Form, Input, Textarea, Select, Check, Scope } from '@rocketseat/unform'

import { FiChevronLeft } from 'react-icons/fi'
import {AiOutlineMail, AiOutlinePhone, AiFillEdit} from 'react-icons/ai'

import Tooltip from '../../../components/tooltip/index'
import api from '../../../services/api'

import {Return, UsuarioInfo, Container, MedicalInfo} from './styles'
import Loading from '../../../components/loading/index'

export default function Paciente(){

    const { params } = useRouteMatch();

    const [paciente, setPaciente] = useState([]);
    const [informacoesMedicas, setInformacoesMedicas] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData(){
            setLoading(true)
            try {
                await api.get(`form/list/${params.paciente}`).then((response) => {
                    setInformacoesMedicas(response?.data)
                    setLoading(false)
                }).catch((error) => {
                    setInformacoesMedicas(undefined)
                    let erro = JSON.parse(error.request.response)
                    toast.info(erro.error)
                })
                setLoading(true)
                await api.get(`user/user/${params.paciente}`).then((response) => {
                    setPaciente(response.data)
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
            } catch (error) {
                setLoading(false)
                toast.error('Ocorreu um erro ao listar os dados. Entre em contato com o suporte.')
            }
        }
        fetchData()
    },[params.paciente])

    async function handleSubmitRegister(data){
        try {
            if(!informacoesMedicas){
                await api.post(`form/register`, {
                    user: params.paciente,
                    PersonalInformation: data?.PersonalInformation,
                    nutritionalSemiology: data?.nutritionalSemiology,
                    waterConsumption: data?.waterConsumption,
                    allergiesAndIntolerances: data?.allergiesAndIntolerances,
                    useOfMedicines: data?.useOfMedicines,
                    physicalActivity: data?.physicalActivity,
                    alcoholicBeverage: data?.alcoholicBeverage,
                    smoking: data?.smoking,
                    schedules: data?.schedules,
                    dietaryEvaluation: data?.dietaryEvaluation,
                    preferencesAndAversions: data?.preferencesAndAversions,
                    foodIngestion: data?.foodIngestion,
                    anthropometricEvaluation: data?.anthropometricEvaluation,
                    consultaDate: data?.consultaDate
                }).then((response) => {
                    setLoading(true)
                    setInformacoesMedicas(response.data)
                    setLoading(false)
                    toast.success('Dados cadastrados.')
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })    
            } else {
                await api.put(`form/edit/${informacoesMedicas?._id}`, {
                    PersonalInformation: data?.PersonalInformation,
                    nutritionalSemiology: data?.nutritionalSemiology,
                    waterConsumption: data?.waterConsumption,
                    allergiesAndIntolerances: data?.allergiesAndIntolerances,
                    useOfMedicines: data?.useOfMedicines,
                    physicalActivity: data?.physicalActivity,
                    alcoholicBeverage: data?.alcoholicBeverage,
                    smoking: data?.smoking,
                    schedules: data?.schedules,
                    dietaryEvaluation: data?.dietaryEvaluation,
                    preferencesAndAversions: data?.preferencesAndAversions,
                    foodIngestion: data?.foodIngestion,
                    anthropometricEvaluation: data?.anthropometricEvaluation,
                    consultaDate: data?.consultaDate
                }).then((response) => {
                    setLoading(true)
                    setInformacoesMedicas(response.data)
                    toast.success('Dados atualizados')
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })               
            }
        } catch (error) {
            toast.error('Ocorreu um erro ao registrar os dados. Entre em contato com o suporte.')
        }
    }

    if(loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return (
            <Container>
                <Return>
                    <Link to="/pacientes">
                        <FiChevronLeft/>
                        Voltar
                    </Link>
                </Return>
        
                <UsuarioInfo>
                    <header>
                        <img alt="Carol Bernadino Nutricionista"  src={paciente?.avatar}/>
                        <div>
                            <strong>
                                {paciente?.name}
                            </strong>
                            <p>
                                <AiOutlineMail/> {paciente?.email}
                            </p>
                            <span>
                            <AiOutlinePhone size={18}/> {paciente?.phone}
                            </span>
                            <br/>
                        </div>
                    </header>
                    <hr/>
                </UsuarioInfo>
    
                <MedicalInfo>
                    <Form onSubmit={handleSubmitRegister} initialData={informacoesMedicas}>
                        <h4>1. INFORMAÇÕES PESSOAIS</h4>
                        <Scope path="PersonalInformation">
                            <div>
                                <div>
                                    <Input label="Data de nascimento" type="date" name="dateBirth" placeholder="Data de nascimento" required/>
                                </div>
                                <div>
                                    <Select label="Estado civil" name="maritalStatus" id="maritalStatus" options={[{id: "solteiro", title: 'Solteiro'}, {id: "casado", title: 'Casado'}, {id: "divorciado", title: 'Divorciado'}]} placeholder="Selecione uma opção"/>  
                                </div>
                                <div>
                                    <Input label="Peso" type="Number" step="0.01"  name="Weight" placeholder="Peso em kg" required/>
                                </div>
                                <div>
                                    <Input label="Altura" type="Number" max="3.00" min="0" step="0.01" name="height" placeholder="Altura em metros" required/>
                                </div>               
                            </div>
        
                            <div>
                                <div>
                                    <Select label="Trânsito intestinal" name="IntestinalTransit" id="IntestinalTransit" options={[{id: "1x/dia", title: '1x/dia'}, {id: "2x/dia", title: '2x/dia'}, {id: "3x/dia", title: '3x/dia'}, {id: "4x/dia", title: '4x/dia'}, {id: "5x/dia", title: '5x/dia'}, {id: "Prisão de ventre", title: 'Prisão de ventre'}]} placeholder="Selecione uma opção" /> 
                                </div>
                                <div>
                                    <Select label="Escala urinária" name="UrinaryStaining" id="UrinaryStaining" options={[{id: "1", title: '1'}, {id: "2", title: '2'}, {id: "3", title: '3'}, {id: "4", title: '4'}, {id: "5", title: '5'}, {id: "6", title: '6'}, {id: '7', title: '7'}, {id: '8', title: '8'}]} placeholder="Selecione uma opção" />                                    
                                </div>
                                <div>
                                    <Select label="Gênero fisiológico" name="genre" id="genre" options={[{id: "Mulher", title: 'Mulher'}, {id: "Homem", title: 'Homem'}]} placeholder="Selecione uma opção" required/>
                                </div>
                                <div>
                                    <Select label="Escala de bristol" name="escalaBristol" id="escalaBristol" options={[{id: "1", title: '1'}, {id: "2", title: '2'}, {id: "3", title: '3'}, {id: "4", title: '4'}, {id: "5", title: '5'}, {id: "6", title: '6'}, {id: "7", title: '7'}]} placeholder="Selecione uma opção" /> 
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Input label="Profissão" type="text" name="profession" placeholder="Profissão" />
                                </div>  
                            </div>
        
                            <div>
                                <div>
                                    <Textarea label="Qualidade do sono" name="sleepQuality" id="sleepQuality" placeholder="Breve relato" rows="6" cols="30"/>
                                </div>
                                
                                <div>
                                    <Textarea label="Histórico clínico" id="clinicalHistory" name="clinicalHistory" rows="6" cols="30" placeholder="Histórico cliníco do paciente"/>
                                </div>
        
                                <div>
                                    <Textarea label="Objetivo" id="objective" name="objective" rows="6" cols="30" placeholder="Objetivo para com o acompanhamento"/>
                                </div>
                            </div>
                            <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        </Scope>
                        <hr/>
    
                        <h4>2. SEMIOLOGIA NUTRICIONAL</h4>
                        <Scope path="nutritionalSemiology">
                            <h5>2.1 ALTERAÇÃO DE PESO</h5>
                            <Scope path="weightChanges">
                                <div>
                                    <div>
                                        <Select label="Tipo" name="kgChanges" id="kgChanges" options={[{id: "PerdaPeso", title: 'Perda de Peso'}, {id: "GanhoPeso", title: 'Ganho de Peso'}]} placeholder="Selecione uma opção"/>
                                    </div>
                                    <div>
                                        <Input label="Quantidade" type="Number" max="400" step="0.1" name="obsWeight" placeholder="Peso em kg" />
                                    </div>               
                                </div>
                            </Scope>
        
                            <Scope path="physicalExam">
                                <h5>2.2 EXAME FÍSICO</h5>
                                <div>
                                    <div>
                                        <Check label="Pele ressecada"  id="drySkin" name="drySkin" />
                                    </div>
            
                                    <div>
                                        <Check label="Umidade das mucosas"  id="mucousMoisture" name="mucousMoisture"/>
                                    </div>
                                    <div>
                                        <Check label="Queda de cabelo"  id="lossOfHair" name="lossOfHair"/>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Check label="Edema"  id="edema" name="edema"/>
                                    </div>
                                    <div>
                                        <Check label="Fraqueza" id="weakness" name="weakness"/>
                                    </div>
                                    <div>
                                        <Check label="Palidez conjutival" defaultChecked="sim" id="conjunctivalPallor" name="conjunctivalPallor"/>
                                    </div>
                                    <div>
                                        <Check label="Unhas quebradiças" id="koilonychicNails" name="koilonychicNails"/>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Textarea label="Observação" id="obsPhysicalExam" name="obsPhysicalExam" rows="6" cols="30"/>
                                    </div>
                                </div>
                            </Scope>
                            <h5>2.3 ALTERAÇÃO NO APARELHO DIGESTIVO</h5>

                            <Scope path="disgestiveSystem">
                                <div>
                                    <div>
                                        <Check label="Má digestão" id="dyspepsia" name="dyspepsia" />
                                    </div>
            
                                    <div>
                                        <Check label="Dores estomacais" id="stomachPains" name="stomachPains"/>
                                    </div>
                                    <div>
                                        <Check label="Nauseas" id="nausea" name="nausea"/>
                                    </div>
            
                                    <div>
                                        <Check label="Vômitos" id="vomiting" name="vomiting"/>
                                    </div>
                                    <div>
                                        <Check label="Dificuldade de engolir" id="dysphagia" name="dysphagia"/>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Textarea label="Observação" id="obsDisgestiveSystem" name="obsDisgestiveSystem" rows="6" cols="30" />
                                    </div>
                                </div>
                            </Scope>
                            <h5>2.4 ALTERAÇÕES INTESTINAIS</h5>
                            <Scope path="intestinalChanges">
                                <div>
                                    <div>
                                        <Check label="Diarreia" id="diarrhea" name="diarrhea"/>
                                    </div>
                                    <div>
                                        <Check label="Normal" id="normal" name="normal"/>
                                    </div>
                                    <div>
                                        <Check label="Constipação" id="cold" name="cold"/>
                                    </div>
                                    <div>
                                        <Textarea label="Observação" id="obsIntestinalChanges" name="obsIntestinalChanges" rows="6" cols="30" />
                                    </div>
                                </div>
                            </Scope>
                            <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        </Scope>
                        <hr/>
    
                        <h4>3. CONSUMO DE ÁGUA</h4>
                        <div>
                            <div>
                                <Textarea labe="Descrição" id="waterConsumption" name="waterConsumption" rows="6" cols="30" />
                            </div>
                        </div>
                        <hr/>
        
                        <h4>4. ALERGIAS E INTOLERÂNCIAS</h4>
                        <Scope path="allergiesAndIntolerances">
                            <div>
                                <div>
                                    <Textarea label="Alergia alimentar" id="foodAllergy" name="foodAllergy" rows="6" cols="30" />
                                </div>
        
                                <div>
                                    <Textarea label="Outras alergias" id="otherAllergies" name="otherAllergies" rows="6" cols="30" />
                                </div>
        
                                <div>
                                    <Textarea label="Intolerâncias" id="intolerances" name="intolerances" rows="6" cols="30" />
                                </div>
                            </div>
                            <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        </Scope>
                        <hr/>
    
                        <h4>5. USO DE MEDICAMENTOS/SUPLEMENTOS</h4>
                        <Scope path="useOfMedicines">
                            <div>
                                <div>
                                    <Textarea label="Medicamentos/Suplementos" id="medicinesOrSupplements" placeholder="Exemplo: whey; creatina; complexo b..." name="medicinesOrSupplements" rows="6" cols="30"/>
                                </div>
                                <div>
                                    <Textarea label="Doses" id="dose" placeholder="Dose correspondente ao medicamento do campo anterior" name="dose" rows="6" cols="30"/>
                                </div>
                                <div>
                                    <Textarea label="Horários" id="schedule" placeholder="O horário de cada medicamento informado no campo anterior" name="schedule" rows="6" cols="30"/>
                                </div>
                            </div>
                            <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        </Scope>
                        <hr/>
    
                        <h4>6. ATIVIDADE FÍSICA</h4>
                        <Scope path="physicalActivity">
                            <div>
                                <div>
                                    <Check label="Pratica atividade física" id="physicalActivityYesNo" name="physicalActivityYesNo"/>
                                </div>
                                <div>
                                    <Textarea label="Descrição da frequência" id="frequencyActivity" name="frequencyActivity" rows="6" cols="30" />
                                </div>
                            </div>
                        </Scope>
                        <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        <hr/>
    
                        <h4>7. BEBIDA ALCOÓLICA</h4>
                        <Scope path="alcoholicBeverage">
                            <div>
                                <div>
                                    <Check label="Ingestão de bebida alcoólica" id="yesNoBeverage" name="yesNoBeverage"/>
                                </div>
                                <div>
                                    <Textarea label="Descrição da frequência" id="frequencyBeverage" name="frequencyBeverage" rows="6" cols="30" />
                                </div>
                            </div>
                        </Scope>
                        <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        <hr/>
    
                        <h4>8. TABAGISMO</h4>                    
                        <div>
                            <div>
                                <Check label="Fumante" id="smoking" name="smoking"/>
                            </div>
                        </div>
                        <hr/>
    
                        <h4>9. HORÁRIOS</h4>
                        <Scope path="schedules">
                            <div>
                                <div>
                                    <Input label="Acorda às" type="time" name="wakeUp" />
                                </div>
                                <div>
                                    <Input label="Dorme às" type="time" name="sleeps" />
                                </div>
                                <div>
                                    <Input label="Atividade física às" type="time" name="physicalActivity" />
                                </div> 
                            </div>
                            <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        </Scope>
                        <hr/>
    
                        <h4>10. AVALIAÇÃO DIETÉTICA</h4>
                        <Scope path="dietaryEvaluation">
                            <div>
                                <div>
                                    <Textarea label="Refeições local/horários" id="mealAndScheduleAndLocal" name="mealAndScheduleAndLocal" rows="6" cols="30"/>
                                </div>
                                <div>
                                    <Textarea label="Alimentos" placeholder="Alimentos correspondente a cada refeição" id="foods" name="foods" rows="6" cols="30"/>
                                </div>
                                <div>
                                    <Textarea label="Quantidades" placeholder="Quantidade referente a cada alimento adicionado" id="quantities" name="quantities" rows="6" cols="30"/>
                                </div>
                            </div>
                        </Scope>

                        <h5>10.1. PREFERÊNCIAS E AVERSÕES</h5>
                        <Scope path="preferencesAndAversions">
                            <div>
                                <div>
                                    <Textarea label="Preferências" placeholder="Lista de preferências" id="preferences" name="preferences" rows="6" cols="30"/>
                                </div>
                                <div>
                                    <Textarea label="Aversões" placeholder="Lista de aversões" id="aversions" name="aversions" rows="6" cols="30"/>
                                </div>
                            </div>
                        </Scope>

                        <h5>10.2. ALTERAÇÕES DA INGESTÃO ALIMENTAR</h5>
                        <Scope path="foodIngestion">
                            <div>
                                <div>
                                <Textarea label="Inapetência" id="Inapetência" name="inappetence" rows="6" cols="30" />
                                </div>
                                <div>
                                    <Textarea label="Hiperfagia" id="hyperphagia" name="hyperphagia" rows="6" cols="30" />
                                </div>
                            </div>
                            <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                        </Scope>
                        <hr/>

                        <h4>11. AVALIAÇÃO ANTROPOMÉTRICA</h4>
                        <Scope path="anthropometricEvaluation">
                            <div>
                                <div>
                                    <Input label="Paramêtros" type="date" name="date" placeholder="Data" />
                                </div>
                                <div>
                                    <Input label="Nível de atividade física" type="Number" step="0.1" min="1" max="1.4" name="NAF" placeholder="Em centímetros"/>
                                </div>
                                <div>
                                    <Input label="Peso atual" type="Number" step="0.1" name="currentWeight" placeholder="Em kg"/>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Input label="circunferência abdominal" type="Number" step="0.1" name="ThoracicSkinfold" placeholder="Em centímetros" />
                                </div>
                                <div>
                                    <Input label="Circunferência da cintura" type="Number" step="0.1" name="waist" placeholder="Em centímetros" />
                                </div>
                                <div>
                                    <Input label="Circunferência do braço" type="Number" step="0.1" name="arm" placeholder="Em centímetros" />
                                </div>
                                <div>
                                    <Input label="Circunferência do quadril" type="Number" step="0.1" name="hip" placeholder="Em centímetros" />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Input label="Dobra cutânea bicipital" type="Number" name="bicepsSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea tricipital" type="Number" step="0.1" name="tricepsSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea subescapular" type="Number" step="0.1" name="subscapularSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea axilar média" type="Number" step="0.1" name="mediumAxillarySkinfold" placeholder="Em milimetros" />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Input label="Dobra cutânea suprailíaca" type="Number" step="0.1" name="suprailiacSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea supraespinal" type="Number" step="0.1" name="calfSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea abdominal" type="Number" step="0.1" name="abdominalSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea coxa" type="Number" step="0.1" name="thighSkinfold" placeholder="Em milimetros" />
                                </div>
                            </div>

                            <div>

                                <div>
                                    <Input label="Dobra cutânea panturrilha" type="Number" step="0.1" name="breastplateSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Dobra cutânea peitoral" type="Number" step="0.1" name="breastplateSkinfold" placeholder="Em milimetros" />
                                </div>
                                <div>
                                    <Input label="Circinferência da panturrilha" type="Number" step="0.1" name="calf" placeholder="Em centímetros" />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Input label="Densidade corporal" type="Number" name="bodyDensity" id="bodyDensity" disabled/>
                                </div>
                                <div>
                                    <Input label="Percentual de gordura" type="Number" name="fatPercentage" id="fatPercentage" disabled/>
                                </div>
                                <div>
                                    <Input label="Peso gordo" type="Number" name="fatWeight" id="fatWeight" disabled/>
                                </div>
                                <div>
                                    <Input label="Peso magro" type="Number" name="thinWeight" id="thinWeight" disabled/>
                                </div>
                            </div>
                            <Scope path="energyExpenditure">
                                <div>
                                    <div>
                                        <Input label="Harris-Benedict" type="Number" name="HarrisBenedict" disabled/>
                                    </div>
                                    <div>
                                        <Input label="FAO/OMS" type="Number" name="faoOms" disabled/>
                                    </div>
                                    <div>
                                        <Input label="IOM" type="Number" step="0.1" name="iom" disabled/>
                                    </div>
                                </div>
                            </Scope>
                            <div>
                                <div>
                                    <Input label="IMC" name="imc" disabled/>
                                </div>
                                <div>
                                    <Input label="Necessidade hídrica diária" name="dailyHydraulicNeed" disabled/>
                                </div>
                            </div>
                        </Scope>
                        <hr/>
                        <div>
                            <div>
                                <Input label="Data da consulta" type="date" name="consultaDate" />
                            </div>
                        </div>
                        <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Salvar</button>
                    </Form>
                </MedicalInfo>
            </Container>
        )
    }
}