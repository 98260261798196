import styled from 'styled-components';

import { darken } from 'polished';

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const Container = styled.div`
  background: white;
  width: 100%;
  height: 90vh;
  max-width: 600px;
  padding: 0.5rem 2rem 1rem;
  border-radius: 5px;
  box-shadow: 0 0 60px rgba(0,0, 0, 0.05);
  text-align: left;
  position: relative;
  overflow: hidden;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  header button {
    border: none;
    background: transparent;
    margin: 0px;
    padding: 0px;
  }

  section {
    padding: 1rem;
    width: 100%;
    height: 80%;
    position: relative;
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
    overflow: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
  }

  li {
    list-style: disc;
    margin-bottom: 1rem;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 5px;
  }

  footer button {
    border: 0;
    border-radius: 4px;

    padding: 0.5rem;

    background: #3b9eff;
    font-weight: bold;
    color #fff;
    font-size: 16px;
    transition: background 0.3s;

    &:hover {
        background: ${darken(0.05, '#0885ff')}
    }
  }
`
