import styled, {keyframes} from 'styled-components';

const spin = keyframes`
    to {
        transform: rotate(360deg);
    }

`

const bg = keyframes`

    50% {
        color: #831e62;
    }

`

export const Container = styled.div`
    width: 100%;
    display: flex;

    flex-direction column;
    align-items: center;

    padding-top: 125px;

    h1 {
        animation: ${bg} 1.5s infinite;
        text-shadow: 2px 2px 8px black;

        max-width: 450px;
        color: #C0C0C0;
    }

    .loading{
        animation: ${spin} 1.5s linear infinite;
        width: 200px;
        height: 200px;
        border: 16px solid #831e62;

        border-top-color: #be317f;
        border-radius: 50%;
    }

`