import React, {useEffect, useState, useContext } from 'react';
import {Link} from 'react-router-dom'

import { Hint } from 'react-autocomplete-hint';
import {toast} from 'react-toastify'

import { FcSearch    } from "react-icons/fc";
import {AiFillPlusCircle} from 'react-icons/ai'

import api from '../../services/api'
import {AutocompleteContext} from '../../context/autocomplete/AutocompleteContext'

import MyTooltip from '../tooltip/index'

import {Formulario, Container, Carregando} from './styles'

const Autocomplete = (props) => {

    const { BuscandoPorEmail } = useContext(AutocompleteContext)
    const { BuscandoPorNome } = useContext(AutocompleteContext)
    const { setPaciente } = useContext(AutocompleteContext)

    const {
        parametroDeBusca, //Parametro pelo qual o usuário vai ser buscado - Nome, E-mail...
        urlFull, //URL na qual será feita a requisição da lista completa do Moldel para o Hint utilizar na busca
        urlBusca, //URL na qual será feita a busca após a pesquisa
        urlRegister, // URL para redirecionar, caso exista o componente de registro, para a area de registro
        verificadorRegister, //Verificador se existirá o componente de novo registro
        textTooltip, // texto que ira no tooltip caso exista o icone de registro
        tooltipPosition, // posição do tooltip (right, left, top, bottom)
        textPlaceholder // texto do placeholder do input
      } = props;

    // Criando os estados do componente
    const [hintData, setHintData] = useState([]) //Array com a lista de Models requisitado
    const [text, setText] = useState('') //texto que está sendo digitado no input
    const [loading, setLoading] = useState(true) //Verificador de carregamento



    useEffect(()=> {
        async function getData(){
            setPaciente(undefined)
            try {
                await api.get(urlFull).then((res)=>{
                    var hintArray = []
                    if(parametroDeBusca === "name"){
                        res.data.docs.map(a => hintArray.push(a.name))
                        setHintData(hintArray)
                    } else {
                        if(parametroDeBusca === "title"){
                            res.data.docs.map(a => hintArray.push(a.title))
                            setHintData(hintArray)
                        } else {
                            res.data.docs.map(a => hintArray.push(a.email))
                            setHintData(hintArray)
                        }
                    }
                    setLoading(false)
                }).catch((error)=>{
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
            } catch (error) {
                toast.error('Erro ao carregar autocomplete.')
            }
        }

        getData()

    }, [urlFull])

    async function handleClick(){
        try {
            if(parametroDeBusca === "name" || parametroDeBusca === "title"){
                await BuscandoPorNome({
                    urlBusca: urlBusca,
                    text: text,
                    parametroDeBusca: parametroDeBusca
                })
            } else {
                await BuscandoPorEmail({
                    urlBusca: urlBusca,
                    text: text,
                    parametroDeBusca: parametroDeBusca
                })
            }


        } catch (error) {
            console.log(error)
            toast.error('Ocorreu um erro. Entre em contato com o suporte.')
        }
    }

  return (
        <>
        {
            loading
            ?
            <Carregando>Carregando auto complete</Carregando>
            :
            <Container>
                <Formulario onSubmit={e => { e.preventDefault()}}>
                    <Hint options={hintData} allowTabFill>
                        <input value={text} className='input-with-hint' placeholder={textPlaceholder} onChange={e => setText(e.target.value)}/>
                    </Hint>
                    <button onClick={handleClick} type="button"><FcSearch size={24} /></button>
                    {
                        verificadorRegister
                        ?
                        <MyTooltip texto={textTooltip} position={tooltipPosition}>
                            <div>
                                <Link to={urlRegister}>
                                    <AiFillPlusCircle size={60}/>
                                </Link>
                            </div>
                        </MyTooltip>
        
                        :
                        undefined
                    }
                </Formulario>
            </Container>
        }
        </>
  );
}

export default Autocomplete;
