import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const MyTooltip = (props) => {

    const {
        texto,
        position
    } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
        <span style={{textDecoration: "underline", color:"blue"}} href="#" id="TooltipExample">{props.children}</span>
        <Tooltip placement={position} isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
          {texto}
        </Tooltip>
  </div>
  );
}

export default MyTooltip;