import React, { createContext, useCallback, useState } from "react"
import api from '../../services/api'
import {toast} from 'react-toastify'

export const AutocompleteContext = createContext()

export const AutocompleteProvider = ({children}) => {
    
    const [paciente, setPaciente] = useState(undefined);


    const BuscandoPorNome = useCallback( async ({ urlBusca, text}) => {
        try {
            if(text === ''|| text === undefined){
                toast.info('Escreva um nome para a realização da busca')
            } else {
                api.get(`${urlBusca}${text}`).then((response) => {
                    setPaciente(response.data)
                    toast.success('Lista atualizada.')
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                }) 
            }
            } catch (error) {
                toast.error('Ocorreu um erro ao carregar as informações.')
            }
        }, [])
    
        const BuscandoPorEmail = useCallback( async ({ urlBusca, text}) => {
            try {
                if(text === ''|| text === undefined){
                    toast.info('Escreva um nome para a realização da busca')
                } else {
                    api.get(`${urlBusca}${text}`).then((response) => {
                        setPaciente([response.data])
                        toast.success('Lista atualizada.')
                    }).catch((error) => {
                        let erro = JSON.parse(error.request.response)
                        toast.error(erro.error)
                    }) 
                }
                } catch (error) {
                    toast.error('Ocorreu um erro ao carregar as informações.')
                }
            }, [])


    return (
        <AutocompleteContext.Provider value={{ paciente: paciente, setPaciente,  BuscandoPorEmail, BuscandoPorNome }}>
            {children}
        </AutocompleteContext.Provider>
    )
}