import React from 'react';
import './styles.js'

import MyCarousel from '../../../components/carousel/index'
import foto1 from '../../../assets/imagens/insta1.jpg'
import foto2 from '../../../assets/imagens/insta2.jpg'
import foto3 from '../../../assets/imagens/nutri1.jpg'
import foto4 from '../../../assets/imagens/nutri2.jpg'

import logoNameRosa from '../../../assets/logos/logo-com-nome-rosa.svg'

import Mapa from '../../../components/map/index'

import {Container, About, Insta} from './styles'

export default function LandingPage(){
    return (
        <>
            <MyCarousel/>
            <Container>
                <About>
                    <img src={logoNameRosa} alt="Carol-nutricionista"/>
                    <div>
                        <p> Sou Carol Bernardino, tenho 22 anos e escolhi cuidar do próximo através do alimento desde os 17 anos quando ingressei no curso de nutrição. Especialista em Nutrição Clínica e Esportiva, atuo nessas áreas acolhendo cada paciente com muito amor e afeto, abraçando cada objetivo individualmente e segurando a mão para percorrer o melhor trajeto para o resultado. No lindo laço formado por Nutricionista e paciente, me transbordo e vibro cada conquista como se fosse minha.
                        </p>
                        <p>
                        “É o invisível que produz o visível”. Ao longo dos quatro anos de graduação pude moldar o meu interior, esse invisível tão importante para demonstração do amor pelo cuidado e pela profissão que escolhi, que tem sido intensificado na convivência com todos que optam por ser do meu time.
                        </p>
                        <p>
                        Agradeço a confiança no meu trabalho e reafirmo: No <strong>#TeamCarol</strong> o amor é transmitido através do alimento!
                        </p>
                    </div>
                </About>

                <Insta >

                    <a href="https://www.instagram.com/carolbl_/?hl=pt-br" target="_blank" rel="noreferrer">
                        <img src={foto4} alt="Carol-nutricionista"/>
                        <div className="cabec">
                            <span>carolbl_</span>
                            <p>siga-nos no instagram e venha participar do <strong>#TeamCarol</strong></p>
                        </div>
                    </a>
                    <div>
                        <img src={foto1} alt="Carol-nutricionista"/>
                        <img src={foto2} alt="Carol-nutricionista"/>
                        <img src={foto3} alt="Carol-nutricionista"/>
                    </div>
                </Insta>                
        </Container>
        <Mapa/>

        </>
    )
}