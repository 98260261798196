import styled from 'styled-components';

import logoCard from '../../assets/logos/card-logo-rosa.svg'

export const Container = styled.div`
    margin: auto;
    margin-top: 15px;

    .card{
        border-radius: 16px;
        border:none;
        height:364px;
        width:100%;

        background-image: url(${logoCard});
        @media(max-width: 800px) {
            width: 100%;
            height:100%;
        }

        .content{
            padding: 25px 50px;

            strong{
                @media(max-width: 800px) {
                    padding: 0 5px;
                    font-size: 0;
                }
            }

            p{
                align: justify;
                color:#be317f;
                padding-left:30px;

                @media(max-width: 800px) {
                    padding-left:0;
                }
            }

            a {
                text-decoration: none;
                align: justify;
                padding-left:30px;
                font-size: 24px;

                @media(max-width: 800px) {
                    font-size: 20px;
                    padding-left:0;
                }
            }
        }
    }

`