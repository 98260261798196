import styled, {keyframes} from 'styled-components';

const spin = keyframes`
    to {
        transform: rotateZ(360deg);
        
        50% {
            color: #fff;
        }
    }

`

const title = keyframes`

    50% {
        color: #C0C0C0;
    }
    10% {
        color: transparent;
    }

`

export const Container = styled.div`
    width: 100%;
    display: flex;

    flex-direction column;
    align-items: center;

    padding-top: 15px;

    P{
        color: #DCDCDC;
        animation: ${title} 1.8s linear infinite;
    }

    .loading{
        animation: ${spin} 1.8s linear infinite;
        width: 125px;
        height: 125px;
        border: 12px solid #DCDCDC;

        border-right-color:transparent;
        border-left-color:transparent;

        border-radius: 50%;
    }

`