import React, { useState, useRef, useEffect, useContext } from 'react';

import {Container, Artigo} from './styles'
import Logo from '../../../assets/logos/logo-branca.svg'
import api from "../../../services/api"

import {toast} from 'react-toastify'
import Loading from '../../../components/loading/index'

import {FiChevronRight} from 'react-icons/fi'

import Autocomplete from '../../../components/autocomplete/index'

import {AutocompleteContext} from '../../../context/autocomplete/AutocompleteContext'

export default function Artigos(){

    const { paciente } = useContext(AutocompleteContext)

    const [artigos, setArtigo] = useState([]);
    const [loading, setLoading] = useState(true);

    //Objeto mutavel
    const cb = useRef() //serve para ser a causa de disparo do usuEffect quando  este Component é gerado, ou seja, useEffect será chamado quando o usuário entrar nesta página.

    //função que é chamada por uma causa. Sempre que cb mudar, está função será chamada
    useEffect(() => {
        async function fetchData(){
            try {
                await api.get('artigo/list').then((response) => {
                    setArtigo(response.data)
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                }) 
            } catch (error) {
                setLoading(true)
                toast.error('Erro ao buscar os artigos. Entre em contato com o suporte.')
            }
    
        }
        fetchData()
    },[cb])

    if (loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return(
            <Container>
                <div>
                    <img src={Logo} alt="Carol Nutri"/>
                    <h1>Explore a lista de artigos.</h1>
                </div>
                    
                <Autocomplete urlFull={`artigo/listFull`} urlBusca="artigo/listName/" urlRegister="/registrar-artigo" parametroDeBusca="title" textTooltip="Registrar novo artigo" urlEdit verificadorRegister={false} tooltipPosition="right" textPlaceholder="Informe o nome do artigo"/>

                {
                    paciente?
                    <>
                        {paciente.map(artigo => (     
                        <Artigo>
                            <a key={String(artigo._id)} href={artigo.link} target="_blank" rel="noreferrer">
                                <div>
                                    <div className="conteudo">
                                        <strong>{artigo.title}</strong>
                                        <p>{artigo.description}</p>
                                    </div>
                                </div>

                                <FiChevronRight size={20}/>
                            </a>
                        </Artigo>
                        ))}
                    </>
                    :
                    undefined
                }
    
                {artigos.map(artigo => (
                    
                    <Artigo>
                        <a key={String(artigo._id)} href={artigo.link} target="_blank" rel="noreferrer">
                            <div>
                                <div className="conteudo">
                                    <strong>{artigo.title}</strong>
                                    <p>{artigo.description}</p>
                                </div>
                            </div>
    
                            <FiChevronRight size={20}/>
                        </a>
                    </Artigo>
                ))}
            </Container>
        )
    }
}