import styled, { keyframes } from 'styled-components'

import { darken } from 'polished'

const DeBaixoParaCima = keyframes`
    from{
        opacity: 0;
        transform: translateY(70px)

    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
`

export const Wrapper = styled.div`
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Content = styled.div`
    animation: ${DeBaixoParaCima} 1.7s;
    height: 100%;
    width: 100%;
    max-width: 350px;
    text-align: center;
    padding: 25px 0;
    margin: 0 1rem;
    overflow-y: auto;

    img {
        height: 120px;

        @media(max-width: 800px) {
            height: 100px;;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
    }

    input {
        background: rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 4px;
        height: 36px;
        padding: 0 15px;
        color: #fff;
        margin: 0 0 10px;

        @media(max-width: 800px) {
            font-size:16px;
            height: 40px;
        }

        &::placeholder {
            color: rgba(255, 255, 255, 0.7);
        }
    }

    span {
        color: #fff;
        align-self: self-start;
        margin: 0;

        font-size:14px;
    }

    button {
        margin: 5px 0 0;
        height: 40px;
        background: #3b9eff;
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 18px;
        transition: background 0.3s;

        @media(max-width: 800px) {
            font-size:14px;
            height: 40px;
        }

        &:hover {
            background: ${darken(0.05, '#0885ff')}
        }
    }
`

export const PrivacyPolicyInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 5px;

    position: relative;

    input {
        position: absolute;
        top: -5px;
        left: 10px;
    }
    label {
        margin-left: 30px;
        color: white;
        text-align: left;
    }

    label span {
        font-weight: 700;
    }

    label span:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    @media(max-width: 800px) {
        input {
            position: absolute;
            top: -3px;
            left: 10px;
        }
    }
`