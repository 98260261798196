import styled, { keyframes} from 'styled-components';


const DaDireitaParaEsquerda = keyframes`
    from{
        opacity: 0;
        transform: translateX(10%)

    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const Container = styled.div`
    max-width: 60%;
    padding-top: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;
    
    .cabe {
        display: flex;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: start;
        margin:0;

        a {
            svg{
                margin-left:10px;
                color: #fff;
                transition: .6s;
                cursor:pointer;
                &:hover {
                    transform: rotate(90deg);
                }
            }
        }
    
        img {
            height: 75px;
            margin: 0;
            align-item: center;
        }
    
        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin: 0;

        }
    }

    .calendario{
        background: #fff;
        border: none;
        border-radius: 6px;
        width: 100%;
        height:100%;
        padding:0;
        margin-top:15px;

        color: #831e62;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin-top: 30px;
    }
`;

export const Time = styled.li`
    animation: ${DaDireitaParaEsquerda} 0.2s;
    min-width: 100%;
    padding: 20px;
    border-radius: 8px;
    background: #fff;

    border-left: 10px;
    border-left-color: #be317f;

    a {
        display: block;
        text-decoration: none;
        color: #831e62;
    }

    opacity: ${props => (props.past ? 0.6 : 1)};

    strong {
        display: block;
        text-decoration: none;
        color: ${props => (props.available ? '#831e62' : '#831e62')};
    }

    span {
        display: block;
        text-decoration: none;
        margin-top: 3px;
        font-weight: bold;
        font-size: 18px;
        color: ${props => (props.available ? '#831e62' : '#831e62')};
    }
`