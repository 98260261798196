import React from 'react';

import {Container} from './styles'

const Jumbotron_ = (props) => {
  return (
    <Container>
      <div className="card">
        <div className="content">
          {props.children}
        </div>
      </div>
    </Container>
  );
};

export default Jumbotron_;