import React, { useState, useEffect, useContext } from 'react';
import {Link} from 'react-router-dom'

import {toast} from 'react-toastify'
import {FiChevronRight, FiChevronLeft} from 'react-icons/fi'

import {Container, Paciente} from './styles'
import Logo from '../../../assets/logos/logo-branca.svg'
import api from "../../../services/api"

import Autocomplete from '../../../components/autocomplete'
import Loading from '../../../components/loading/index'

import {AutocompleteContext} from '../../../context/autocomplete/AutocompleteContext'

export default function Pacientes(){

    const { paciente } = useContext(AutocompleteContext)

    const [pacientes, setPacientes] = useState([]);
    const [pacienteInfo, setPacienteInfo] = useState();
    const [pagina, setPagina] = useState(1)
    const [loading, setLoading] = useState(true);

    //função que é chamada por uma causa. Sempre que cb mudar, está função será chamada
    useEffect(() => {
        async function fetchData(){
            setLoading(true)
            try {
                await api.get(`/user/users?page=${pagina}`).then((response) => {
                    setPacientes(response.data.docs)
                    setPacienteInfo(response.data)
    
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                }) 
            } catch (error) {
                toast.error('Ocorreu um erro ao listar os pacientes. Entre em contato com o suporte.')
            }
        }
        fetchData()
    },[pagina])

    function prevPage(){
        if( pagina === 1) return

        setPagina( pagina - 1)
    }

    function nextPage(){
        if( pagina === pacienteInfo.pages) return

        setPagina( pagina + 1)
    }

    function fistPage(){
        if( pagina === 1) return

        setPagina( 1 )
    }

    function finalPage(){
        if( pagina === pacienteInfo.pages) return

        setPagina( pacienteInfo.pages )
    }


    if (loading){
        return <><Loading><h1>Carregando</h1></Loading></>
    } else {
        return(
            <Container>
                    <div>
                        <img alt="Carol Bernadino Nutricionista"  src={Logo}/>
                        <h1>Explore a lista dos pacientes.</h1>
                    </div>

                <Autocomplete urlFull={`/user/usersFull`} urlBusca="user/userName/" urlRegister="/registrar-paciente" parametroDeBusca="name" textTooltip="Registrar novo usuário" urlEdit verificadorRegister={true} tooltipPosition="right" textPlaceholder="Informe o nome do paciente"/>

                {
                paciente ?
                    paciente.map(paciente => (
                        <>
                        <Paciente>
                            <Link key={String(paciente._id)}  to={`/paciente/${paciente._id}`}>
                                <img alt="Carol Bernadino Nutricionista"  src={paciente.avatar}/>
                                <div>
                                    <div className="conteudo">
                                        <strong>{paciente.name}</strong>
                                        <p>{paciente.email}</p>
                                    </div>
                                </div>
                                <FiChevronRight size={20}/>
                            </Link>
                        </Paciente>
                        <hr/>
                        </>
                    ))
                :
                    undefined
                }
    
                {pacientes.map(paciente => (
                    <Paciente>
                        <Link key={String(paciente._id)}  to={`/paciente/${paciente._id}`}>
                            <img alt="Carol Bernadino Nutricionista"  src={paciente.avatar}/>
                            <div>
                                <div className="conteudo">
                                    <strong>{paciente.name}</strong>
                                    <p>{paciente.email}</p>
                                </div>
                            </div>
                            <FiChevronRight size={20}/>
                        </Link>
                    </Paciente>
                ))}

                <div className="actions">
                    <div className="voltar">
                        <button disabled={pagina === 1} onClick={fistPage}>
                        <strong> &lt; &lt;</strong> Primeira pagina
                        </button>
                        <button disabled={pagina === 1} onClick={prevPage}>
                            <FiChevronLeft size={18}/> Página {pagina-1}
                        </button>
                    </div>

                    <div className="avancar">
                        <button disabled={pagina === pacienteInfo.pages} onClick={nextPage}>
                            Página {pagina+1} <FiChevronRight size={18}/>
                        </button>
                        <button disabled={pagina === pacienteInfo.pages} onClick={finalPage}>
                            Última página {pacienteInfo.paginas} <strong>&gt; &gt;</strong>
                        </button>
                    </div>
                </div>

            </Container>
        )
    }
}
