import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'

import api from "../../../services/api"
import Tooltip from '../../../components/tooltip/index'
import Loading from '../../../components/loadingSmall/index'
import Calendar from 'react-calendar';

import Logo from '../../../assets/logos/logo-branca.svg'
import {AiFillPlusCircle} from 'react-icons/ai'

import {toast} from 'react-toastify'

import {Container, Time} from './styles.js'

export default function Scheduling(){
    const [ schedule, setSchedule ] = useState();
    const [ date, setDate ] = useState(new Date());
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function fetchData(){
            setLoading(true)

            var data = ''
            if(date.getMonth() < 10){
                data = `0${date.getMonth()+1}-${date.getFullYear()}`
            }else {
                data = `${date.getMonth()+1}-${date.getFullYear()}`
            }
            if(date.getDate() < 10){
                data = `0${date.getDate()}-${data}`
            } else {
                data = `${date.getDate()}-${data}`
            }
    
            try {
                await api.get(`agendamento/list/${data.toString()}`).then((response) => {
                    setSchedule(response.data)
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
            } catch (error) {
                toast.error('Ocorreu um erro ao tentar listar os agendamentos. Entre em contato com o suporte.')
            }
    
        }
        fetchData()
        
    },[date])

    const onChange = date => {
        setDate(date)
    }
        return (
            <Container>
                
                <div className="cabe">
                    <img src={Logo} alt="Carol Nutri"/>
                    <h1>Explore os agendamentos</h1>
                    <Link to="/cadastrar-agendamento">
                        <Tooltip position="right" texto="Clique para cadastrar nova data de agendamento">
                            <AiFillPlusCircle size={60}/>
                        </Tooltip>
                    </Link>
                </div>

                <Calendar className="calendario" onChange={onChange} value={date}/>

                {
                    loading
                    ?
                    <Loading available>Aguarde</Loading>
                    :
                    <ul>
                        { schedule.map(agendamento => (
                            <div key={String(agendamento._id)} >
                                {
                                    agendamento.status ? <Time available ><Link to={`/editar-agendamento/${agendamento._id}`}><strong >{agendamento.status ? 'Disponível para agendamento' : 'Não Disponível'}</strong><span>{agendamento.hours}</span></Link></Time> : <Time past ><Link to={`/editar-agendamento/${agendamento._id}`}><strong >{agendamento.status ? 'Disponível para agendamento' : 'Não Disponível'}</strong><span>{agendamento.hours}</span></Link></Time>
                                }
                            </div>
                        ))}
                    </ul>

                }

            </Container>
        )
        
    
}

