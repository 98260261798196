import styled, {keyframes} from 'styled-components'

const DaDireitaParaEsquerda = keyframes`
    from{
        opacity: 0;
        transform: translateX(40%)

    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const Container = styled.div`
    animation: ${DaDireitaParaEsquerda} 1s;
    padding-top: 5%;
    padding-left: 20%;
    padding-bottom: 5%;

    div {
        display: flex;
        min-height: 100%;

        img {
            height: 75px;
            margin: 0;
            align-item: center;
        }

        h1 {
            font-size: 36px;
            line-height: 48px;
            margin: auto 0;
            color: #fff;
        }

    }

`

export const Artigo = styled.div`
    min-height: 100%;
    margin-top: 20px;
    width: 80%;

    a{
        background: #fff;
        border-radius:8px;
        width:100%;
        padding: 16px;
        display: block;
        text-decoration: none;

        display: flex;
        align-items: center;
        transition: transform 0.6s;

        &:hover{
            transform: translateX(50px);
        }

        div{
            display: flex;
            flex-direction: column;

            margin-left: 16px;

            strong {
                padding:0px;
                margin:0px;
                font-size: 20px;
                color: #be317f;
            }

            p{
                color: #831e62;
                padding:0px;
                margin:0px;
                text-align: justify;
            }

        }
        svg{
            margin-left: auto;
            color: #831e62;
            min-width: 5%;
        }
    }

`