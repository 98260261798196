import styled, {keyframes} from 'styled-components'

const DaDireitaParaEsquerda = keyframes`
    from{
        opacity: 0;
        transform: translateX(40%)

    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const Container = styled.div`
    animation: ${DaDireitaParaEsquerda} 1s;
    padding-top: 5%;
    padding-left: 20%;
    padding-bottom: 5%;

    div {
        display: flex;
        min-height: 100%;
    
        img {
            height: 75px;
            margin: 0;
            align-item: center;
        }
    
        h1 {
            font-size: 36px;
            line-height: 48px;
            margin: auto 0;
            color: #fff;
        }
    
    }

    .actions {
        display: flex;
        justify-content: space-between;
        width: 80%;
        padding: 15px 0;

        button{
            padding: 10px;
            margin: 0 10px;
            border: 0;
            fint-size: 8;
            cursor: pointer;

            background: #f;
            color: #831e62;
            border-radius: 4px;

            &:hover{
                background: #ffff;
                transition: 0.5s;
            }
    
        }

        button[disabled]{
            opacity: 0.5;
            cursor: defaut;

            &:hover{
                opacity: 0.5;
                cursor: default;
            }
        }
    }

`

export const Receita = styled.div`
    min-height: 100%;
    margin-top: 20px;
    width: 80%;

    a{
        background: #fff;
        border-radius:8px;
        width:100%;
        padding: 16px;
        display: block;
        text-decoration: none;

        display: flex;
        align-items: center;
        transition: transform 0.6s;

        &:hover{
            transform: translateX(50px);
        }


        img {
            width: 75px;
            height: 75px;
            border-radius:50%;
            margin: 0;
        }


        div{
            display: flex;
            flex-direction: column;

            margin-left: 16px;

            strong {
                padding:0px;
                margin:0px;
                font-size: 20px;
                color: #be317f;
            }

            p{
                color: #831e62;
                padding:0px;
                margin:0px;
                text-align: justify;
            }

        }
        svg{
            margin-left: auto;
            color: #831e62;
            min-width: 5%;
        }
    }

`