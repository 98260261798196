import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 20px;
    width: 100%;
    height: 100%;


    .tabs{
        div{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            h5 {
                color: #fff;
                text-shadow: 1px 1px 3px #922369;
                transition: 1s;
                @media(max-width: 800px) {
                    font-size:0;    
                }
            }

            svg{
                @media(min-width: 800px) {
                    font-size:0;    
                }
            }
        }
        @media(max-width: 800px) {
            width: 100%;;
        }
    }

    a.active.nav-link {
        background: #fff;
        cursor: pointer;

        h5 {
            color: #922369;
        }
    }

    a.nav-link {
        background: #922369;
        cursor: pointer;
        border-bottom: solid 1px #fff;

    }
`

export const Content1 = styled.div`
    display: flex;
    margin: auto;
`