import styled from 'styled-components'

export const Container = styled.div`
    padding: 50px;
    @media(max-width: 800px) {
        padding: 15px 0;
    }
    background: #831e62;

    display: flex;
    justify-content: center;
    align-items: center;

    .leaflet-container{
        position: relative;
        width: 80%;
        height: 500px;
        @media(max-width: 800px) {
            width: 80%;
            height: 200px;
        }
        border-radius: 50px;
    }
`