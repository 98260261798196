import React, { createContext, useState } from 'react';

export const ModalsContext = createContext();

export const ModalsProvider = ({ children }) => {
  const [isOpenPolicyPrivacy, setIsOpenPolicyPrivacy] = useState(false);
  const [isOpenTermsOfUse, setIsOpenTermsOfUse] = useState(false);

  return(
    <ModalsContext.Provider value={{
      isOpenPolicyPrivacy,
      setIsOpenPolicyPrivacy, 
      isOpenTermsOfUse, 
      setIsOpenTermsOfUse
    }}>
      { children }
    </ModalsContext.Provider>
  );
}