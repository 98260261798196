import React from 'react';
import { Alert } from 'reactstrap';

import {Container} from './styles'

const Alerta = (props) => {

    const {
        tipo,
      } = props;

  return (
    <Container>
      <Alert color={tipo}>
        {props.children}
      </Alert>
    </Container>
  );
};

export default Alerta;