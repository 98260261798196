import styled, {keyframes} from 'styled-components'
import imageCarol from '../../../assets/imagens/nutri4.jpeg'

const animateOpaciy = keyframes`
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px 0 0 0;

    background: url(${imageCarol}) no-repeat;
    background-position:  right top;
    background-size: auto 100%;
    background-color: #fff;
    background-origin: padding-box;

    @media(max-width: 1200px) {
        background-size: auto 60%;
        background-position:  right top;
    }

    @media(max-width: 800px) {
        background-size: auto 45%;
        background-position:  right bottom;
    }
`

export const About = styled.div`   
    @media(max-width: 800px) {
        padding: 10px;            
    }

    img {
        width: 50%;
        height: 50%;
        padding: 25px;
        margin:0;

        @media(max-width: 800px) {
            width: 100%;
            height: 100%;
            padding: 10px;            
        }
    }
    
    div {
        width:100%;
        display: flex;
        flex-direction: column;
        padding: 0 750px 0 50px;
        transition: 2s;

        @media(max-width: 1200px) {
            padding: 0 400px 0 50px;
        }
        @media(max-width: 800px) {
            padding: 5px;
        }
        p{
            text-align: justify;
            font-size: 20px;
            align: center;
            animation: ${animateOpaciy} 3s cubic-bezier(.17,.67,.49,1.02);
            animation-fill-mode: forwards;

            @media(max-width: 800px) {
                text-align: justify;
                font-size: 15px;
                align: center;
                padding:0;
            }
        }
        h1 {
            color: #831e62;

            @media(max-width: 800px) {
                padiing: 0;
                margin:0;
                font-size: 18px;
            }
        }
        strong {
            color: #831e62;
        }
    }
`
export const Insta = styled.div`
    background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration: none;
    color: #A9A9A9;
    transition: 0.5s;
    padding: 75px 0;

    border-radius: 0 150% 0 0;
    box-shadow: 3px 3px #808080, -1em 0 0.4em olive;

    nav {
        width:100%;
        background: #FFFFFF;
        padding: 15px 0 15px 0;


        img{
            margin-left: 4%;
            width:10%;
            height: 10%;
        }
    }


    a {
        display: flex;
        margin: 4%;
        text-decoration: none;
        color: #831e62;
        img{
            margin: 1%;
            width:15%;
            height: 15%;
            border-radius: 50%;
            border-style: groove;

            @media(max-width: 800px) {
                width:20%;
                height: 20%;
            }
        }

        .cabec {
            display: flex;
            justify-content: center;
            flex-direction: column;

            span {
                margin-bottom: 5%;
                margin-left: 8%;
                font-size: 28px;

                @media(max-width: 800px) {
                    font-size: 18px;
                }
            }
            p {
                margin-left: 8%;
                font-size: 20px;

                @media(max-width: 800px) {
                    font-size: 10px;
                }

                strong {
                    color: #831e62;
                }
            }
        }

    }

    div {
        padding: 0 4%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        
        img {
            width: 30%;
            height: 30%;
            margin: 0.5%px;
        }
    }
`