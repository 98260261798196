import React from 'react'
import {FaLinkedin} from 'react-icons/fa'

import {Container} from './styles.js'

export default function Footer(){
    return (
            <Container>
                <div id="container">
                    <div id="cont">
                        <div className="footer_center">
                            <h3>
                                © 2020 Copyright: Dev orion || devorion01@gmail.com
                                <a href="https://www.linkedin.com/in/gilvan-carlos/" target="_blank" rel="noreferrer">
                                    <FaLinkedin /> Junior Teixeira
                                </a>
                                <a href="https://www.linkedin.com/in/marcosadriano05/" target="_blank" rel="noreferrer">
                                    <FaLinkedin /> Marcos Adriano
                                </a>
                                <a href="https://www.linkedin.com/in/mateus-santos-aa0623196/" target="_blank" rel="noreferrer">
                                    <FaLinkedin /> Mateus Loiola
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>
            </Container>

    )
}
