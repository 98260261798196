import React, { useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom'

import api from "../../../services/api"
import {Container, Time} from './styles.js'
import Logo from '../../../assets/logos/logo-com-nome-branca.svg'

import Loading from '../../../components/loadingSmall/index'
import Tooltip from '../../../components/tooltip/index'

import {toast} from 'react-toastify'

import Autocomplete from '../../../components/autocomplete'
import Tabs from '../../../components/tabs/index'
import Toggle from '../../../components/Toogle/index'

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { FcPlanner } from "react-icons/fc";

import {AutocompleteContext} from '../../../context/autocomplete/AutocompleteContext'

export default function Dashboard(){

    const { paciente } = useContext(AutocompleteContext)
    
    const [ schedule, setSchedule ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ consultas, setconsultas ] = useState([]);
    const [ date, setDate ] = useState(new Date());

    useEffect(() => {
        async function fetchData(){
            setLoading(true)

            var data = ''
            if(date.getMonth() < 10){
                data = `0${date.getMonth()+1}-${date.getFullYear()}`
            }else {
                data = `${date.getMonth()+1}-${date.getFullYear()}`
            }
            if(date.getDate() < 10){
                data = `0${date.getDate()}-${data}`
            } else {
                data = `${date.getDate()}-${data}`
            }
            
            try{
                await api.get(`consultas/consulta/${data.toString()}`).then((response) => {
                    setconsultas(response.data)
                    setLoading(false)
                }).catch((error) => {
                    setconsultas([])
                    setLoading(false)
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
    
                setLoading(true)
                await api.get(`agendamento/list/${data.toString()}`).then((response) => {
                    setSchedule(response.data)
                    setLoading(false)
                }).catch((error) => {
                    setSchedule(undefined)
                    setLoading(false)
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
            }catch (error) {
                toast.error('Erro ao buscar os consultas. Entre em contato com o suporte.')
            }
        }
        fetchData()
    },[date])

    const onChange = date => {
        setDate(date)
    }

        return (
            <Container>
                <img alt="Carol Bernadino Nutricionista"  src={Logo}/>

                <Toggle className="content">
                    <Tooltip texto="Click para abrir o calendário" position="right">
                        <FcPlanner size={24}/>
                    </Tooltip>
                    <Calendar className="calendario" onChange={onChange} value={date}/>
                </Toggle>
                
                <Tabs title1="Agendamento" title2="Consultas marcadas"  >
                    <div>
                        <Autocomplete urlFull={`/user/usersFull`} urlBusca="consultas/consultaUser/" urlRegister="/registrar-paciente" parametroDeBusca="email" textTooltip="Registrar novo usuário" urlEdit verificadorRegister={false} tooltipPosition="" textPlaceholder="Informe o e-mail do paciente"/>

                        {
                        paciente ?
                        <div className="consultas">
                            <ul>
                                { paciente.map(consulta => (
                                    <div key={String(consulta._id)} >
                                        {
                                            consultas ? <Time><Link to={`/consulta/${consulta._id}`}><strong >Paciente: {consulta?.user?.name}</strong><span>E-mail: {consulta?.user?.email}</span><p>Situação: {consulta?.situation}</p><p>Horário: {consulta?.data?.hours}</p></Link></Time> : <h3>Nada marcado para este dia</h3>
                                        }
                                    </div>
                                ))}
                            </ul>
                        </div>
                        :
                        undefined
                        }

                        {
                            loading 
                            ?
                            <Loading>Aguarde</Loading>
                            :
                            <div className="consultas">
                                <ul>
                                    { consultas.map(consulta => (
                                        <div key={String(consulta._id)} >
                                            {
                                                consultas ? <Time><Link to={`/consulta/${consulta._id}`}><strong >Paciente: {consulta?.user?.name}</strong><span>E-mail: {consulta?.user?.email}</span><p>Situação: {consulta?.situation}</p><p>Horário: {consulta?.data?.hours}</p></Link></Time> : <h3>Nada marcado para este dia</h3>
                                            }
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>

                    <div>
                        {
                            loading
                            ?
                            <Loading>Aguarde</Loading>
                            :
                            <div className="agendamento">
                                <ul>
                                    { schedule.map(agendamento => (
                                        <div key={String(agendamento._id)} >
                                            {
                                                agendamento.status ? <Time available ><Link to={`/register-consulta/${agendamento._id}`}><strong>Disponível para agendamento</strong><span>{agendamento.hours}</span><p>Click para agendar uma consulta</p></Link></Time> : undefined
                                            }
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>
                </Tabs>
            </Container>
        )
        
    
}
