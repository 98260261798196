import styled from 'styled-components'

export const Container = styled.div`
    @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);

    bottom:0;
    left:0;
    position:fixed;
    width: 100%;
    height: 15px;
    overflow:hidden;
    margin:0 auto;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    z-index:999;
    opacity: 0;

    &:hover {
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
        height: 150px;
        opacity: 1;
      }

    #container{
        margin-top:5px;
        width:100%;
        height:100%;
        position:relative;
        top:0;
        left:0;
        background: #fff;

        #cont{
            position:relative;
            padding: 15px;
            width:100%;

            .footer_center{
                width: 100%;
                text-align:center;

                h3{
                    font-family: 'Helvetica';
                    font-size: 20px;
                    font-weight: bold;
                    opacity: 1;

                    @media(max-width: 800px) {
                        font-size: 12px;
                    }

                    a{
                        padding: 5px;
                        font-size: 15px;
                    }  
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
`