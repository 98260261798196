import React, { useState, useEffect, useContext } from 'react';

import {toast} from 'react-toastify'
import {FiChevronRight} from 'react-icons/fi'
import Loading from '../../../components/loading/index'

import {Container, Receita} from './styles'
import Logo from '../../../assets/logos/logo-branca.svg'
import api from "../../../services/api"

import Autocomplete from '../../../components/autocomplete/index'

import {AutocompleteContext} from '../../../context/autocomplete/AutocompleteContext'

export default function Receitas(){

    const { paciente } = useContext(AutocompleteContext)

    const [receitas, setReceitas] = useState([]);
    const [loading, setLoading] = useState(false);

    //Objeto mutavel

    //função que é chamada por uma causa. Sempre que cb mudar, está função será chamada
    useEffect(() => {
        async function fetchData(){
            setLoading(true)
            try {
                await api.get('receitas/list').then((response) => {
                    setReceitas(response.data)
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })      
            } catch (error) {
                setLoading(false)
                toast.error('Erro ao buscar as receitas. Entre em contato com o suporte.')
                console.log(error)
            }
        }
        fetchData()
    }, [])


    if (loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return(
            <Container>
                <div>
                    <img alt="Carol Bernadino Nutricionista"  src={Logo}/>
                    <h1>Explore a lista de receitas.</h1>
                </div>
    
                <Autocomplete urlFull={`receitas/listFull`} urlBusca="receitas/listName/" urlRegister="/registrar-receita" parametroDeBusca="title" textTooltip="Registrar nova receita" urlEdit verificadorRegister={false} tooltipPosition="right" textPlaceholder="Informe o nome da receita"/>
    
                {
                    paciente?
                    <>
                        {paciente.map(receita => (     
                            <Receita key={String(receita._id)}>
                                <a href={receita.link} target="_blank" rel="noreferrer">
                                    <img src={receita.image} alt="Carol Bernadino Nutricionista"/>
                                    <div className="conteudo">
                                        <strong>{receita.title}</strong>
                                        <p>{receita.description}</p>
                                    </div>
                                    <FiChevronRight/>
                                </a>
                            </Receita>
                        ))}
                    </>
                    :
                    undefined
                }
    
                {receitas.map(receita => (
                    <Receita key={String(receita._id)}>
                        <a href={receita.link} target="_blank" rel="noreferrer">
                            <img alt="Carol Bernadino Nutricionista"  src={receita.image}/>
                            <div className="conteudo">
                                <strong>{receita.title}</strong>
                                <p>{receita.description}</p>
                            </div>
        
                            <FiChevronRight/>
                        </a>
                    </Receita>
            ))}
            </Container>
        )
    }
}
