import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding:0;

    button{
        :focus{
            border: none !importante;
            box-shadow: 0 0 0 0;
            border: 0 none;
            outline: 0;  
        }
    }
    div{
        padding: 5px;
    }
`
