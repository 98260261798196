import React, { useState, useContext} from 'react';
import { Form, Input } from '@rocketseat/unform'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import HCaptcha from '@hcaptcha/react-hcaptcha';

import logo from '../../../assets/logos/logo-branca.svg'
import { Wrapper, Content, PrivacyPolicyInput } from './styles'
import {AuthContext} from '../../../context/AuthContext'
import { ModalsContext } from '../../../context/modals/ModalsContext'

import api from '../../../services/api'
import Loading from '../../../components/loading/index'

import { PolicyPrivacyModal } from '../../../components/modals/PolicyPrivacyModal'
import { TermsOfUseModal } from '../../../components/modals/TermsOfUseModal'
import { backgrounds } from 'polished';

const schema = Yup.object().shape({

    name: Yup.string()
        .required("O nome é obrigatório!"),

    email: Yup.string()
        .email("Insira um e-mail válido!")
        .required("O e-mail é obrigatório!"),

    password: Yup.string()
        .required("A senha é obrigatória!"),

    phone: Yup.string()
        .required("O telefone é obrigatório!"),
})


export default function SignUp(){

    const { signIn } = useContext(AuthContext)
    const { 
        isOpenPolicyPrivacy, 
        setIsOpenPolicyPrivacy,
        isOpenTermsOfUse,
        setIsOpenTermsOfUse
    } = useContext(ModalsContext)

    const [loading, setLoading] = useState(false);
    const [verif, setVerif] = useState(false)


    async function onChange(event){
        event.target.value = event.target.value
            .replace(/\D/g,'')
            .replace(/(\d{0})(\d)/, '$1($2')
            .replace(/(\d{2})(\d)/, '$1) $2 ')
            .replace(/(\d{4})(\d)/, '$1-$2')
    }

    async function handlesubmit(data){
        setLoading(true)
        try {
            await api.post('auth/register'  ,{
                name: data.name,
                email: data.email,
                phone: data.phone,
                password: data.password
            }).then(async(response) => {
                await signIn({
                    email: data.email,
                    password: data.password
                })
                setLoading(false)
                toast.success('Cadastro realizado!')
            }).catch((error) => {
                setLoading(false)
                let erro = JSON.parse(error.request.response)
                toast.error(erro.error)
            })
        } catch (error) {
            toast.error('Ocorreu um erro. Entre em contato com o suporte.')
        }
    }

    function handleVerificationSuccess(token, ekey){
        console.log(token)
        console.log(ekey)
        setVerif(true)
    }

    if(loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return (
            <Wrapper>
                <Content>
                    <img src={logo} alt="Carol-nutricionista"/>
    
                    <Form schema={schema} onSubmit={handlesubmit}>
                        <Input name="name" type="text" placeholder="Seu nome completo" />
                        <Input name="email" type="email" placeholder="Seu e-mail" />
                        <Input name="phone" onInput={onChange} maxLength="16" type="text" placeholder="Seu telefone" />
                        <Input name="password" type="password" placeholder="Sua senha" />
                        <PrivacyPolicyInput>
                            <label>
                                Eu concordo com os <span onClick={() => {
                                    setIsOpenTermsOfUse(true)
                                }}>Termos de uso</span> e <span onClick={() =>{
                                    setIsOpenPolicyPrivacy(true)
                                }}>
                                    Política de privacidade
                                </span>
                                <input id="check" name="privacyPolicyAccepted" type="checkbox" required />
                            </label>
                        </PrivacyPolicyInput>
                        <HCaptcha sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                        onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)} />
                        {
                            verif
                            ?
                            <button type="submit" value="Submit"  >Cadastrar</button>
                            :
                            undefined
                        }
                    </Form>
                </Content>
                { isOpenPolicyPrivacy && <PolicyPrivacyModal /> }
                { isOpenTermsOfUse && <TermsOfUseModal /> }
            </Wrapper>
        )
    }    
}