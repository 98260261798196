import React, {useContext, useState} from 'react';

import { FcOldTimeCamera } from 'react-icons/fc';

import { Container, FormImage, FormWrapper, FormInfo } from './styles'
import { Form, Input } from '@rocketseat/unform'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

import {AuthContext} from '../../../context/AuthContext'
import api from '../../../services/api'
import Loading from '../../../components/loading/index'
import LoadingSmall from '../../../components/loadingSmall/index'

const schema1 = Yup.object().shape({

    name: Yup.string()
        .required("O nome é obrigatório!"),

    email: Yup.string()
        .email("Insira um e-mail válido!")
        .required("O e-mail é obrigatório!"),

    phone: Yup.string()
        .required("Número de telefone é obrigatório!"),

    password: Yup.string()
        .required("A senha é obrigatória!"),

})

export default function Profile(){

    const { user } = useContext(AuthContext)
    const { update } = useContext(AuthContext)

    const [loading, setLoading] = useState(false);
    const [loadingImg, setLoadingImg] = useState(false);

    const [currentImg, setCurrentImg] = useState(user.avatar);
    //const [previewImg, setPreviewImg] = useState(null);

    async function handleSubmitImage(e) {
        setLoadingImg(true)
        try {
            e.preventDefault();
            let formData = new FormData();
            formData.append('avatar', e.target[0].files[0]);
            
            await api.post(`user/avatar/${user._id}`, formData)
            .then(res => {
                setCurrentImg(res.data.payload.url);
                setLoadingImg(false)

            })
            .catch(errImage => {
                let err = JSON.parse(errImage.request.response)
                if(err.error.code === "LIMIT_FILE_SIZE") {toast.error("A imagem deve ter no máximo 1 megabyte") }
                toast.error(err.error)
            })
            
        } catch (error) {
            toast.error('Ocorreu um erro ao atualizar os dados. Entre em contato com suporte.')
            setLoadingImg(false)
        }
    }

    async function handlSubmit(data) {
        setLoadingImg(true)
        try {
            await api.put(`user/userUp/${user._id}` ,{
                name: data.name,
                email: data.email,
                phone: data.phone,
            }).then(() => {
                update({
                    email: data.email,
                    password: data.password
                })
                setLoadingImg(false)
            }).catch((error) => {
                let erro = JSON.parse(error.request.response)
                toast.error(erro.error)
                setLoadingImg(false)
            })
        } catch (error) {
            setLoadingImg(false)
            toast.error('Ocorreu um erro ao atualizar os dados. Entre em contato com suporte.')
        }
    }

    async function handlSubmitSenha(data) {
        setLoading(true)
        try {
            if( data.confirmeNewpassword === data.newPassword){
                if(data.confirmeNewpassword.length > 6 && data.newPassword.length > 6){
                    await api.put(`user/userUpPassword/${user._id}` ,{
                        password: data.password,
                        newPassword: data.newPassword,
                    }).then(() => {
                        toast.success('senha atualizada.')
                        setLoading(false)
                    }).catch((error) => {
                        setLoading(false)
                        let erro = JSON.parse(error.request.response)
                        toast.error(erro.error)
                    })
                } else {
                    setLoading(false)
                    toast.info('A senha deve possuir mais de 6 caracteres.')
                }
            } else {
                setLoading(false)
                toast.info('A nova senha difere da confirmação da senha.')
            }
        } catch (error) {
            setLoading(false)
            toast.error('Ocorreu um erro ao atualizar os dados. Entre em contato com suporte.')
        }
    }



    async function onChange(event){
        event.target.value = event.target.value
            .replace(/\D/g,'')
            .replace(/(\d{0})(\d)/, '$1($2')
            .replace(/(\d{2})(\d)/, '$1) $2 ')
            .replace(/(\d{5})(\d)/, '$1-$2')
    }

    if(loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return (
            <Container>
                <FormWrapper>
                    <FormImage>
                        {<form onSubmit={handleSubmitImage} encType="multipart/form" >
                            {
                            loadingImg ?
                            <LoadingSmall/> :
                            <div style={{background: `url(${currentImg}) center no-repeat`}}></div>
                            }
                            <label htmlFor="input-file">
                                <FcOldTimeCamera />
                            </label>
                            <input id="input-file" type="file" name="avatar" accept="image/png, image/jpeg, image/jpg"/>
                            <button type="submit">Ver</button>
                        </form>}
                        <span>Atualize o perfil para salvar a imagem</span>
                    </FormImage>

                    
                    <FormInfo>
                        <Form schema={schema1} initialData={user} onSubmit={handlSubmit}>
                            <Input label="Nome e sobrenome" name="name" placeholder="Nome e sobrenome"/>
                            <Input label="E-mail" type="email" name="email" placeholder="Nome e sobrenome"/>
                            <Input label="Telefone" onChange={onChange} maxLength="16" type="text" name="phone"/>
                            <Input label="Informe sua senha atual" type="password" name="password" placeholder="Necessario para validar as modificações" />
                            <button type="submit">Atualizar perfil</button>
                        </Form>
                    </FormInfo>
                </FormWrapper>
                <hr/>
                <div>
                    <h1>Editar senha</h1>
                </div>
                <FormInfo>
                    <Form onSubmit={handlSubmitSenha}>   
                        <Input label="Nova Senha" type="password" name="newPassword" maxLength="12" minLength="6" placeholder="Mínimo 6 caracteres e máximo  12 caracters" />
                        <Input label="Confirme nova senha" type="password" name="confirmeNewpassword" minLength="6" maxLength="12" placeholder="Mesma senha do campo anterior" />
                        <Input label="Senha atual" type="password" name="password"  placeholder="Necessario para validar as modificações" />
                        <button type="submit">Atualizar senha</button>
                        <hr/>
                    </Form>
                </FormInfo>

            </Container>
        )
    }
}