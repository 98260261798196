import React, { useState, useRef, useEffect, useContext } from 'react';
import {Link} from 'react-router-dom'

import {toast} from 'react-toastify'
import {FiChevronRight} from 'react-icons/fi'

import {Container, Receita} from './styles'
import Logo from '../../../assets/logos/logo-branca.svg'
import api from "../../../services/api"
import Loading from '../../../components/loading/index'
import Autocomplete from '../../../components/autocomplete/index'

import {AutocompleteContext} from '../../../context/autocomplete/AutocompleteContext'

export default function Receitas(){

    const { paciente } = useContext(AutocompleteContext)

    const [receitas, setReceitas] = useState([]);
    const [loading, setLoading] = useState(false);

    //Objeto mutavel
    const cb = useRef() //serve para ser a causa de disparo do usuEffect quando  este Component é gerado, ou seja, useEffect será chamado quando o usuário entrar nesta página.

    //função que é chamada por uma causa. Sempre que cb mudar, está função será chamada
    useEffect(() => {
        async function fetchData(){
            setLoading(true)
            try {
                await api.get('receitas/list').then((response) => {
                    setReceitas(response.data)
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                }) 
            } catch (error) {
                toast.error('Ocorreu um erro ao listar as receitas. Entre em contato com o suporte.')
            }
    
        }
        fetchData()
    },[cb])


    if (loading){
        return (
            <Loading><h1>Carregando</h1></Loading>
        )
    } else {
        return(
            <Container>
                <div>
                    <img src={Logo} alt="Carol Nutri"/>
                    <h1>Explore a lista de receitas.</h1>
                </div>

                <Autocomplete urlFull={`receitas/listFull`} urlBusca="receitas/listName/" urlRegister="/registrar-receita" parametroDeBusca="title" textTooltip="Registrar nova receita" urlEdit verificadorRegister={true} tooltipPosition="right" textPlaceholder="Informe o nome da receita"/>
    
                {
                    paciente?
                    <>
                        {paciente.map(receita => (     
                            <Receita>
                                <Link key={String(receita._id)}  to={`/receita/${receita._id}`}>
                                    <img src={receita.image} alt="Carol Bernadino Nutricionista"/>
                                    <div className="conteudo">
                                        <strong>{receita.title}</strong>
                                        <p>{receita.description}</p>
                                    </div>
                                    <FiChevronRight/>
                                </Link>
                            </Receita>
                        ))}
                    </>
                    :
                    undefined
                }

                {receitas.map(receita => (
                    <Receita>
                        <Link key={String(receita._id)}  to={`/receita/${receita._id}`}>
                            <img src={receita.image} alt="Carol Bernadino Nutricionista"/>
                            <div className="conteudo">
                                <strong>{receita.title}</strong>
                                <p>{receita.description}</p>
                            </div>
                            <FiChevronRight/>
                        </Link>
                    </Receita>
                ))}
            </Container>
        )
    }
}
