import React, { useState, useContext, useEffect, useMemo } from 'react';

import {Container, MedicalInfo, Time, Content, MedicalInfo2, Radio} from './styles.js'
import Logo from '../../../assets/logos/logo-com-nome-branca.svg'

import { format, subDays, addDays} from 'date-fns'
import pt from 'date-fns/locale/pt'

import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import {  AiOutlineMan, AiOutlineWoman} from 'react-icons/ai'
import { FcPlanner, FcLike, FcDislike, FcFinePrint, FcBriefcase, FcInfo, FcDownload } from "react-icons/fc"
import { FaBirthdayCake } from 'react-icons/fa'
import { GiBodyHeight, GiWeight } from 'react-icons/gi'
import { ImSleepy2 } from 'react-icons/im'

import Loading from '../../../components/loadingSmall/index'
import Alerta from '../../../components/alerts'
import Tabs from '../../../components/tabs'
import Jumbotron from '../../../components/jumbotron'

import {AuthContext} from '../../../context/AuthContext'

import { Form, Input, Textarea, Select, Scope, Choice } from '@rocketseat/unform'
import {toast} from 'react-toastify'

import Btipo1 from '../../../assets/bristol/tipo1.png'
import Btipo2 from '../../../assets/bristol/tipo2.png'
import Btipo3 from '../../../assets/bristol/tipo3.png'
import Btipo4 from '../../../assets/bristol/tipo4.png'
import Btipo5 from '../../../assets/bristol/tipo5.png'
import Btipo6 from '../../../assets/bristol/tipo6.png'
import Btipo7 from '../../../assets/bristol/tipo7.png'

import Utipo1 from '../../../assets/Urina/escala1.png'
import Utipo2 from '../../../assets/Urina/escala2.png'
import Utipo3 from '../../../assets/Urina/escala3.png'
import Utipo4 from '../../../assets/Urina/escala4.png'
import Utipo5 from '../../../assets/Urina/escala5.png'
import Utipo6 from '../../../assets/Urina/escala6.png'
import Utipo7 from '../../../assets/Urina/escala7.png'
import Utipo8 from '../../../assets/Urina/escala8.png'

import api from '../../../services/api'
import { useHistory } from 'react-router-dom'

const optionsBristol = [
        { 
            value: '1',
            label: <img src={Btipo1} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '2',
            label: <img src={Btipo2} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '3',
            label: <img src={Btipo3} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '4',
            label: <img src={Btipo4} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '5',
            label: <img src={Btipo5} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '6',
            label: <img src={Btipo6} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '7',
            label: <img src={Btipo7} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
    ]

    const optionsUrina = [
        { 
            value: '1',
            label: <img src={Utipo1} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '2',
            label: <img src={Utipo2} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '3',
            label: <img src={Utipo3} className="radio-image" alt="Carol Bernardino Nutricionista" />
        },
        { 
            value: '4',
            label: <img src={Utipo4} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '5',
            label: <img src={Utipo5} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '6',
            label: <img src={Utipo6} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '7',
            label: <img src={Utipo7} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
        { 
            value: '8',
            label: <img src={Utipo8} className="radio-image" alt="Carol Bernardino Nutricionista"/>
        },
    ]


export default function Home(){

    var history = useHistory()

    const { user } = useContext(AuthContext)

    const [consulta, setConsulta] = useState({});
    const [verConsulta, setVerConsulta] = useState();
    const [verDepoiment, setVerDepoiment] = useState();

    async function handlSubmit(data) {
        setLoading(true)
        try {
            await api.put(`consultas/edit-consulta/${consulta._id}` ,{ 
                testimony: data.testimony,
             }).then(async () => {
                toast.success('Dados atualizados')
                setLoading(false)
                history.go('/home')
            }).catch((error) => {
                let erro = JSON.parse(error.request.response)
                toast.error(erro.error)
            })
        } catch (error) {
            setLoading(true)
            toast.error('Erro ao editar a Consulta. Entre em contato com o suporte.')
        }
    }

    const [ loading, setLoading ] = useState(false);
    const [verInfoMed, setVerInfoMeds] = useState(false)
    const [InfoMed, setInfoMeds] = useState({})

    useEffect(() => {

        async function fetchData(){
            try {
                await api.get(`form/list/${user._id}`).then((response) => {
                    setVerInfoMeds(true)
                    setInfoMeds(response.data?.PersonalInformation)
                }).catch((error) => {
                })
    
                await api.get(`consultas/consultaUser/${user.email}`)
                .then((response) => {

                    setConsulta(response?.data)
                    setVerConsulta(true)
                    
                    if(response.data.situation === "Finalizado"){
                        setVerDepoiment(true)
                    } else {
                        setVerDepoiment(false)
                    }
    
                }).catch(() => {
                    setVerConsulta(false)
                    setConsulta([])
                })    
            } catch (error) {
                toast.error('Ocorreu um erro ao listar os dados. Entre em contato com o suporte.')
            }
        }
        setLoading(true)
        fetchData()
        setLoading(false)
    }, [user._id, user.email])

    async function handleSubmitRegister(data){
        try {
            await api.post(`form/register`, {
                user: user._id,
                PersonalInformation: data.PersonalInformation,
            }).then(async () => {
                toast.success('Dados atualizados')
                setLoading(false)
                history.go('/home')
            }).catch((error) => {
                setLoading(false)
                let erro = JSON.parse(error.request.response)
                toast.error(erro.error)
            })
        } catch (error) {
            toast.error('Ocorreu um erro ao registrar os dados. Entre em contato com o suporte.')
        }
    }

    const [ schedule, setSchedule ] = useState([]);
    const [ date, setDate ] = useState(new Date());
    const [ verSchedule, setVerSchedule ] = useState(false);

    const dateFormatted = useMemo(
        () => format(date, "d 'de' MMMM", { locale: pt}),
        [date]
    );

    useEffect(() => {
        async function fetchData(){
            setLoading(true)

            var data = ''
            if(date.getMonth() < 10){
                data = `0${date.getMonth()+1}-${date.getFullYear()}`
            }else {
                data = `${date.getMonth()+1}-${date.getFullYear()}`
            }
            if(date.getDate() < 10){
                data = `0${date.getDate()}-${data}`
            } else {
                data = `${date.getDate()}-${data}`
            }
            
            try{
                await api.get(`agendamento/list/${data.toString()}`).then((response) => {
                    setSchedule(response?.data)
                    if(response.data.length === 0){
                        setVerSchedule(false)
                    }else{
                        setVerSchedule(true)
                    }
                    setLoading(false)
                }).catch((error) => {
                    let erro = JSON.parse(error.request.response)
                    toast.error(erro.error)
                })
            }catch (error) {
                toast.error('Erro ao buscar os agendamentos. Entre em contato com o suporte.')
            }    
        }
        fetchData()
    },[date])

    function handlePrevDay() {
        setDate(subDays(date, 1));
    }

    function handleNextDay() {
        setDate(addDays(date, 1));
    }

    return(
        <Container>
            <img alt="Carol Bernadino Nutricionista"  src={Logo}/>

            <Tabs title1="Dados médicos" title2="Agendamento de consultas" title3="Minha consulta">
                {
                    verInfoMed
                    ?
                    <Content>
                        <header>
                            <button type="button" onClick={handlePrevDay}> 
                                <MdChevronLeft size={36} color="#FFF"/>
                            </button>
                            <strong>{dateFormatted}</strong>
                            <button type="button" onClick={handleNextDay}> 
                                <MdChevronRight size={36} color="#FFF"/>
                            </button>
                        </header>
                        {
                            verSchedule
                            ?
                            <>
                            {
                                loading
                                ?
                                <Loading available>Aguarde</Loading>
                                :
                                <ul>
                                    { schedule.map(agendamento => (
                                        <div key={String(agendamento._id)} >
                                            {
                                                agendamento.status ? <Time available ><a href={`https://api.whatsapp.com/send?phone=+558494794472&text=Olá Carol. Vi em seu sistema que você possuí disponibilidade de consulta online para o dia ${agendamento.virtualDate} às ${agendamento.hours}. Gostaria de marcar uma consulta nesta data e horário.%20`} target="_blank" rel="noreferrer"><strong >{agendamento.status ? 'Disponível para agendamento' : 'Não Disponível'}</strong><span>{agendamento.hours}</span><p>Click para agendar uma consulta</p></a></Time> : <Time past ><strong >{agendamento.status ? 'Disponível para agendamento' : 'Não Disponível'}</strong><span>{agendamento.hours}</span><p>Horário agendado com um paciente</p></Time>
                                            }
                                        </div>
                                    ))}
                                </ul>
                            }
                            </>

                            :
                            <h2>Nada agendado para este dia</h2>
                        }
                    </Content>
                    :
                    <Alerta tipo="info">
                        <p>Informe os Dados médicos, na aba anterior, para poder solicitar uma consulta</p>
                    </Alerta>
                }

                <div>
                    {
                        verInfoMed
                        ?
                        <>
                        {
                            loading
                            ?
                            <Loading>Aguarde</Loading>
                            :
                            <Jumbotron >
                                <p><FcPlanner/><FaBirthdayCake/><strong> Data de nascimento:</strong> {InfoMed.dateBirth} </p>
                                <p><AiOutlineWoman/><AiOutlineMan/><strong> Gênero fisiológico:</strong> {InfoMed.genre}</p>
                                <p><FcLike/><FcDislike/><strong> Estado civil: </strong> {InfoMed.maritalStatus}</p>
                                <p><FcFinePrint/><FcBriefcase/><strong> Profissão: </strong> {InfoMed.profession}</p>
                                <p><GiBodyHeight/><strong> Altura: </strong> {InfoMed.height} m</p>
                                <p><GiWeight/> <strong>Peso: </strong> {InfoMed.Weight} Kg</p>
                                <p><ImSleepy2/><strong> Qualidade do sono: </strong> {InfoMed.sleepQuality}</p>
                            </Jumbotron>
                        }
                        </>
                        :
                        <MedicalInfo grande pequeno>
                        <Alerta tipo="warning">
                            <p>ATENÇÃO AO INFORMAR OS DADOS. ELES, SÓ PODERÃO SER EDITADOS PELA NUTRICIONISTA.</p>
                        </Alerta>
                            <Form onSubmit={handleSubmitRegister}>
                                <Scope path="PersonalInformation">
                                    <div>
                                        <Input label="Data de nascimento" type="date" name="dateBirth" placeholder="Data de nascimento" required/>

                                        <Select name="maritalStatus" id="maritalStatus" options={[{id: "solteiro", title: 'Solteiro'}, {id: "casado", title: 'Casado'}, {id: "divorciado", title: 'Divorciado'}]} placeholder="Estado civil" required/>  

                                        <Input type="Number" max="400" min="0" step="0.1" name="Weight" placeholder="Peso em kg" required/>

                                        <Input type="Number" max="3.00" min="0" step="0.01" name="height" placeholder="Altura em metros" required/>
                                    </div>
                
                                    <div>
                                        <Input type="text" name="profession" placeholder="Profissão" required/>
                                        <Select name="IntestinalTransit" id="IntestinalTransit" options={[{id: "1x/dia", title: '1x/dia'}, {id: "2x/dia", title: '2x/dia'}, {id: "3x/dia", title: '3x/dia'}, {id: "4x/dia", title: '4x/dia'}, {id: "5x/dia", title: '5x/dia'}, {id: "Prisão de ventre", title: 'Prisão de ventre'}]} placeholder="Trânsito intestinal" required/> 
                                        <Select name="genre" id="genre" options={[{id: "Mulher", title: 'Mulher'}, {id: "Homem", title: 'Homem'}]} placeholder="Gênero fisiológico" required/>
                                    </div>
                
                                    <div>
                                        <Textarea name="sleepQuality" id="sleepQuality" placeholder="Breve relato" rows="6" cols="30" required/>
                                    
                                        <Textarea id="clinicalHistory" name="clinicalHistory" rows="6" cols="30" placeholder="Histórico cliníco do paciente" required/>
            
                                        <Textarea id="objective" name="objective" rows="6" cols="30" placeholder="Objetivo para com o acompanhamento" required/>
                                    </div>
                                    <Alerta tipo="warning">
                                        <p>OS DADOS SEGUINTES SÃO REFERENTES A COR DA URINA E O TIPO DE FEZES</p>
                                    </Alerta>
                                    <div>
                                        <Radio>
                                            <h3>Seu tipo de fezes</h3>
                                            <Choice tam type="radio" name="escalaBristol" id="escalaBristol" options={optionsBristol} className="radio-hidden" required/> 
                                        </Radio>
                                        <Radio>
                                            <hr/>
                                            <h3>Sua coloração urinária</h3>
                                            <Choice name="UrinaryStaining" id="UrinaryStaining" options={optionsUrina} className="radio-hidden" required/>
                                        </Radio>
                                    </div>
                                </Scope>
                                <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Cadastrar dados</button>
                            </Form>
                        </MedicalInfo>
                    }
                </div>
                
                <div>
                    {
                        verConsulta
                        ?
                        <Jumbotron >
                                <p><FcPlanner/><strong>Data: </strong> {consulta.data.virtualDate.replace("-", "/").replace("-", "/")} </p>
                                <p><FcPlanner/><strong>Horário: </strong> {consulta.data.hours} </p>
                                <p><FcInfo/><strong>Situação: </strong> {consulta.situation}</p>
                                <a href={consulta?.project} target="_blank" rel="noreferrer"><FcDownload/>Baixar planejamento</a>

                            {
                                verDepoiment
                                ?
                                <MedicalInfo2>
                                    <Form initialData={consulta} onSubmit={handlSubmit}>
                                        <Scope path="testimony">
                                            <div>
                                                <Select name="like" id="like" options={[{id: "Amei e recomendo", title: 'Amei e recomendo'}, {id: "Gostei", title: 'Gostei'}, {id: "Regular", title: 'Regular'}, {id: "Não recomendo", title: 'Não recomendo'}]} placeholder="Sua opinião sobre o planejamento"/>
                                                <Textarea rows="4" name="depoiment" id="depoiment" placeholder="Relate seu depoimento"/>                  
                                            </div>
                                        </Scope>
                                        <button className="Salve" type="submit" onSubmit={e => { e.preventDefault()}} >Cadastrar depoimento</button>
                                    </Form>
                                </MedicalInfo2>
                                :
                                undefined
                            }
                        </Jumbotron>
                        :
                        <Alerta tipo="warning">
                            <p>Você não possui consulta cadastrada!</p>
                            <hr/>
                            <p>Agende sua consulta na aba de agendamento</p>
                        </Alerta>
                    }
                </div>
            </Tabs>
        </Container>

    )

    
}
