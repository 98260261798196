import styled from 'styled-components'

export const Container = styled.div`
    background: #fff;
`

export const Content = styled.div`

    background: #fff;
    padding:10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav {
        background: #fff;
        display: flex;
        align-items: center;

        img {
            background: #fff;
            height: 64px;

            @media(max-width: 800px) {
                height: 40px;
            }
    
        }

    }

    aside {
        background: #fff;
        display: flex;
        align-items: center;
    }
`
export const Profile = styled.div`
    background: #fff;
    display: flex;
    align-items: center;

    margin-left: 20px;
    padding-left: 20px;

    & > div {
        margin-left: 10px;
        height:60px;
        width:60px;
        border-radius: 50%;
        background-size: cover !important;
    }

    .drop {
        color: #831e62;
        background: #fff;
        &::after{
            transition:1s;
            transform: rotate(-90deg);
        }

        &:link{
            color: #831e62;
            background: #fff;
        }

        &:hover{
            &::after{
                transform: rotate(0);
            }
        }
    }

    .dropOption {
        color: #831e62;
        background: #fff;

        &:link{
            color: #831e62;
            background: #fff;    
        }

        &:hover{
            color: #831e62;
            background: #DCDCDC;    
        }
    }

    a {
        text-decoration: none;
    }
`